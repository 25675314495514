import React, { useState } from "react";
import LoadingBar from "react-top-loading-bar";
import planeImg from "../../assets/images/plane.gif";
import { TimeConvertor } from "../../helpers/utills/TimeConvertor";
import moment from "moment";
import { getConditionsByPrice } from "../../lib/getConditionsByPrice";
import { getPassengersByCount } from "../../lib/getPassengersByCount";
import { DocumentConvertor } from "../../helpers/utills/DocumentConvertor";
import { PassengersConvert } from "../../helpers/utills/PassengersConvert";


const TabDetails = ({ slices, details, passengerDetails, conditionsData }) => {
  const { base_amount, base_currency, tax_amount, tax_currency, total_amount } =
    details;
  const { totalPassengers } = getPassengersByCount(details.passengers);
  console.log("slices", slices);

  const [activeTab, setActiveTab] = useState("fare");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };


  return (
    <div className="flight_more_dtl_box_right_sec">
      <div className="flight_more_dtl_box_right_tab_sec">
        <a
          id="flight_more_btn1"
          className={activeTab === "fare" ? "active" : ""}
          onClick={() => handleTabClick("fare")}
        >
          {" "}
          Fare Details
        </a>
        <a
          id="flight_more_btn2"
          className={activeTab === "baggage" ? "active" : ""}
          onClick={() => handleTabClick("baggage")}
        >
          Baggage Rules
        </a>
        <a
          id="flight_more_btn3"
          className={activeTab === "condition" ? "active" : ""}
          onClick={() => handleTabClick("condition")}
        >
          Condition
        </a>
      </div>
      {activeTab === "fare" && (
        <div className="flight_more_dtl_box_right_tab_cnt1" id="faredtail">
          <div className="flight_more_dtl_box_right_tab_cnt1_lst">
            <span>
              Base Fare ({totalPassengers} Passenger
              {`${totalPassengers > 0 ? "s" : ""}`}){" "}
            </span>
            <span> $ {base_amount}</span>
          </div>
          <div className="flight_more_dtl_box_right_tab_cnt1_lst">
            <span>
              Taxes and Fees ({totalPassengers} Passenger
              {`${totalPassengers > 0 ? "s" : ""}`})
            </span>
            <span>$ {tax_amount}</span>
          </div>
          <div className="flight_more_dtl_box_right_tab_cnt1_lst">
            <span>
              Total Fare ({totalPassengers} Passenger
              {`${totalPassengers > 0 ? "s" : ""}`}){" "}
            </span>
            <span>$ {total_amount} </span>
          </div>
        </div>
      )}
      {activeTab === "baggage" && (
        <div className="flight_more_dtl_box_right_tab_cnt1" id="baggagerule">
          {slices.segments.map((seg, segindex) => (
            <>
              <img
                style={{ width: "20px" }}
                src={seg.marketing_carrier.logo_symbol_url}
              />
              <span
                style={{
                  color: "white",
                  fontSize: "15px",
                }}
              >
                {seg.marketing_carrier.name} (
                {seg.origin.iata_city_code +
                  "-" +
                  seg.destination.iata_city_code}
                )
              </span>
              <div style={{ padding: "10px" }}>
                {seg.passengers.map((passData, passIndex) => (
                  <>
                    {passData.baggages.length > 0 ? (
                      <div
                        className="flight_more_dtl_box_right_tab_cnt1_2nd"
                        key={passIndex}
                      >
                        {PassengersConvert(
                          passengerDetails.filter(
                            (obj) => obj.id == passData.passenger_id
                          )[0]?.type,
                          1
                        )}
                        {passData.baggages.map((baggData, baggIndex) => {
                          return (
                            <>
                              <span key={baggIndex}>
                                {baggData.type == "checked" &&
                                baggData.quantity > 0 ? (
                                  <p>
                                    {" "}
                                    <i
                                      class="fa fa-suitcase"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Includes {baggData.quantity} checked bag
                                  </p>
                                ) : baggData.type == "carry_on" &&
                                  baggData.quantity > 0 ? (
                                  <p>
                                    {" "}
                                    <i class="fas fa-suitcase-rolling"></i>{" "}
                                    Includes {baggData.quantity} carry on bag
                                  </p>
                                ) : (
                                  <p>
                                    <i class="fas fa-suitcase-rolling"></i>{" "}
                                    Includes {DocumentConvertor[baggData.type]}
                                  </p>
                                )}
                              </span>
                            </>
                          );
                        })}
                      </div>
                    ) :null}
                  </>
                ))}
              </div>
            </>
          ))}
        </div>
      )}
      {activeTab === "condition" && (
        <div className="flight_more_dtl_box_right_tab_cnt1" id="condition">
          {console.log("conditionsData", conditionsData)}
          {conditionsData.map((conOgj, conIndex) => {
            return (
              <div className="flight_more_dtl_box_right_tab_cnt1_2nd">
                {console.log("change_before_departure" in conOgj)}
                {"change_before_departure" in conOgj && (
                  <>
                    <span>
                      <i className="fa-solid fa-plane-circle-check"></i>
                    </span>
                    Changeable - {conOgj.change_before_departure.currency}{" "}
                    {conOgj.change_before_departure.penalty_amount}
                  </>
                )}
                {"refund_before_departure" in conOgj && (
                  <>
                    <span>
                      <i className="fa-solid fa-money-bill"></i>
                    </span>
                    Refundable - {conOgj.refund_before_departure.currency}{" "}
                    {conOgj.refund_before_departure.penalty_amount}
                  </>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default TabDetails;
