import React from "react";
import './ErrorPage.css'
import { Scale } from "canvg";
import ErrorImgAnim from './ErrorImgAnim';
import Banner from '../../../assets/images/banner_image.jpg'
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate();

    return (
      <div className="page">
        <header>
          <div className="logo">
            <svg>
              <use xlinkHref="#logo-dailyui" />
            </svg>
          </div>
          <nav></nav>
          <div className="search">
            <svg>
              <use xlinkHref="#ico-search" />
            </svg>
          </div>
        </header>
        <div className="contentnotfound">
          <h1>404</h1>
          <h2>Page not found</h2>
          <p>Something went wrong</p>
          {/* <p>I tried to catch some fog, but I mist</p> */}
          {/* <Button>Back to home</Button> */}
          <a onClick={()=>{navigate("/" )}}>back to home</a>
        </div>
        {/* <ErrorImgAnim/> */}
        <img style={{ position: "absolute", top: 0, left: 0,flex:1, width: '100%',height:'100%',  zIndex: -1, transform: "scale(1.1)" }} src={"http://www.supah.it/dribbble/008/008.jpg"} alt="Error 404" />

      </div>
    );
  };
  
  export default ErrorPage;
  