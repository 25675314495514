import React from "react";
import { Button } from "react-bootstrap";
import moment from "moment";

const MobileView = (props) => {
  const passengerText =
    props?.data?.passengerDetails.length === 1 ? "Passenger" : "Passengers";
  return (
    <>
      <div className="search_result_showInmob">
        <div className="search_result_showInmob_fromTo">
          <div>{props?.data?.origin?.originDetails?.name}</div>
          <span>
            <img src="assets/images/fromTo.png" alt="" />
          </span>
          <div>{props?.data?.destination?.destinationDetails?.name}</div>
        </div>
        <div className="search_reslultDate_ShowMob">
          <span>
            {" "}
            {moment(props?.data?.departureDate).format("ddd MMM DD ")}{" "}
          </span>{" "}
          •{" "}
          <span>
            {props?.data?.passengerDetails?.length} {passengerText}
          </span>
        </div>

        <Button
          className="search_result_showInmobBtn"
          onClick={props.setSearch}
        >
          <i className="fa fa-search"></i>
        </Button>
      </div>

      <div className="mobSortbySection">
        <select
          name=""
          id=""
          className="MobFltrselect"
          onChange={(e) => {
            props.addToFilter(e, "sort");
          }}
        >
          <option value="recommended">Recommended</option>
          <option value="cheapest">Cheapest</option>
          <option value="quickest">Quickest</option>
        </select>
        <Button
          className="filterBoxInMobile"
          id="stopBtn"
          onClick={() => props.setMobFilter("stopsShow")}
        >
          Stops
        </Button>
        <Button
          className="filterBoxInMobile"
          id="dprtTimeBtn"
          onClick={() => props.setMobFilter("dprtlTimeShow")}
        >
          Departure Time
        </Button>
        <Button
          className="filterBoxInMobile"
          id="arrvlTimeBtn"
          onClick={() => props.setMobFilter("arrvlTimeBtn")}
        >
          Arrival Time
        </Button>
      </div>
    </>
  );
};
export default MobileView;
