import React, { useState } from "react";
import dashbaordService from "../../service/dashboard";
import { useAddContactForm } from "../../hooks/dashboard/useDashboard";
import Toaster from "../../components/common/Toaster"
const ContactUs = () => {
  const { mutate, isError, isSuccess, isLoading, status, error, data, reset } =
    useAddContactForm();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });
  const [alert, setalert] = useState({
    status: false,
    message: "", type: ""
  })
  const Dismisstoast = () => {
    setalert({ status: false, message: "", type: "" })
    return true

  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, mobile, message } = formData;
    mutate(formData, {
      onSuccess: (res) => {
        if (res.data.status) {
          setalert({
            status: true,
            type: "success",
            message: "Your message has been sent."
          })
        }
      },
      onError: (error) => {
        setalert({
          status: true,
          type: "error",
          message: "Server Error"
        })
      }
    })
    // let mailtoLink = `mailto:support@ioss.in?subject=Contact%20Us&body=Name: ${name}%0AEmail: ${email}%0A`;

    // if (mobile) {
    //   mailtoLink += `Mobile: ${mobile}%0A`;
    // }

    // mailtoLink += `Message: ${encodeURIComponent(message)}`;

    // window.location.href = mailtoLink;
  };

  return (
    <>
      <Toaster
        show={alert.status}
        variant={alert.type}
        message={alert.message}
        position="top-right"
        theme="dark"
        dismiss={Dismisstoast}
      />
      <section className="innerPageContantArea helppageView">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h2 className="contact_head">Contact Us</h2>
              <div className="contactus_box">
                <strong>Feel free to contact us</strong>
                <a href="tel:+919544931055">
                  <i className="fa-solid fa-phone"></i> +91 9544931055
                </a>
              </div>
              <div className="contactus_box">
                <strong>Email</strong>
                <a href="mailto:support@ioss.in">
                  <i className="fa-regular fa-envelope"></i> support@ioss.in
                </a>
              </div>
              <div className="contactus_box">
                <strong>Address</strong>
                <a>
                  <i className="fa-solid fa-location-dot"></i> Sahya building,
                  Govt Cyber Park,<br />
                  Nellikode (PO) Calicut – 673016,<br />
                  Kerala, India
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="HelpCenterFormBoxSec">
                <form onSubmit={handleSubmit}>
                  <div className="HelpCenterFormBox">
                    <h3 className="mb-5 text-start">Get in touch with us</h3>
                    <div className="row d-flex align-items-center mb-4">
                      <div className="col-md-4">
                        <label htmlFor="">Name *</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="row d-flex align-items-center  mb-4">
                      <div className="col-md-4">
                        <label htmlFor="">Email *</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="row d-flex align-items-center  mb-4">
                      <div className="col-md-4">
                        <label htmlFor="">Mobile No *</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="number"
                          name="mobile"
                          className="form-control"
                          value={formData.mobile}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="row d-flex  mb-3">
                      <div className="col-md-4">
                        <label htmlFor="">Your Message? *</label>
                      </div>
                      <div className="col-md-8">
                        <textarea
                          name="message"
                          className="form-control"
                          value={formData.message}
                          onChange={handleChange}
                          cols="30"
                          rows="5"
                          required
                        ></textarea>
                      </div>
                    </div>

                    <div className="row justify-content-end  mb-3">
                      <button
                        type="submit"
                        className="btn btn-primary help-btn"
                      >
                        {status === "loading" ? 'Sending...' : 'Send'}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
