import React, { useEffect, useLayoutEffect ,useState} from "react";
import { SideBar, NavBar, Footer } from "../shared";
import { useDispatch } from "react-redux";
import { login } from "../store/authSlice";
import { useCheckLogin } from "../hooks/login/useLogin";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
// import { Spinner } from "react-bootstrap";
import Spinner from "../components/common/Spinner";
import { useDashboardcontent } from "../hooks/dashboard/useDashboard";
const MainLayout = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isLoadings, setIsLoading] = useState(true);
  const { mutate, isError, isSuccess, isLoading, error, data, reset } =
    useCheckLogin();

  useEffect(() => {
    let data = {
      token: localStorage.getItem("token"),
    };
    mutate(data, {
      onSuccess: (res) => {
        dispatch(login(res.data.data.email));
        localStorage.setItem("token", res.data.data.token);
      },
      isError: (error) => {
        console.log(error);
      },
    });
  }, []);

  const {
    data: dashboardData,
    isLoading: helperLoading,
    isError: helperError,
  } = useDashboardcontent();
  if (isLoading) {
    return (
      <div className="centered">
        {" "}
        <Spinner/>
     
      </div>
    );
  }

  return (
    <div className="main_layout">
      
      <NavBar />
       <div className="main_wrapper_content">
      <Outlet/>
      </div>
      {console.log("dashboardData",dashboardData)}
      <Footer connectus={dashboardData?.data?.data?.follow}/>
    </div>
  );
};
export default MainLayout;
