import React, { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import PublicLayout from "../layouts/PublicLayout";
import PrivateLayout from "../layouts/PrivateLayout";
import loadable from "@loadable/component";
import ErrorPage from "../components/common/Pagenotfound/ErrorPage";
import Errorboundary from "../components/common/Errorboundary";
import ContactUs from "../pages/dashboard/ContactUs";

const DashBoard = loadable(() => import("../pages/dashboard"), {
  resolveComponent: (components) => components.MainContent,
});
const FlightList = loadable(() => import("../pages/flightlist"), {
  resolveComponent: (components) => components.MainContent,
});
const EarnPoints = loadable(() => import("../pages/dashboard"), {
  resolveComponent: (components) => components.EarnPonits,
});
const Login = loadable(() => import("../pages/login"), {
  resolveComponent: (components) => components.MainContent,
});
const Register = loadable(() => import("../pages/register"), {
  resolveComponent: (components) => components.MainContent,
});
const Booking = loadable(() => import("../pages/booking"), {
  resolveComponent: (components) => components.MainContent,
});
const Succes = loadable(() => import("../pages/booking"), {
  resolveComponent: (components) => components.Succes,
});
const HelpCenter = loadable(() => import("../pages/dashboard"), {
  resolveComponent: (components) => components.HelpCenter,
});
const Account = loadable(() => import("../pages/account"), {
  resolveComponent: (components) => components.MainContent,
});
const FAQ = loadable(() => import("../pages/footer"), {
  resolveComponent: (components) => components.FAQ,
});
const Contact = loadable(() => import("../pages/footer"), {
  resolveComponent: (components) => components.Contact,
});
const Terms = loadable(() => import("../pages/footer"), {
  resolveComponent: (components) => components.Terms,
});
const Privacy = loadable(() => import("../pages/footer"), {
  resolveComponent: (components) => components.Privacy,
});
const Payment = loadable(() => import("../pages/booking"), {
  resolveComponent: (components) => components.PaymentContent,
});
const ForgotPassword = loadable(() => import("../pages/forgot"), {
  resolveComponent: (components) => components.MainContent,
});

const publicRoutes = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <DashBoard />,
      },
      {
        path: "/dashboard",
        element: <DashBoard />,
      },
      {
        path: "/search",
        element: <FlightList />,
      },
    

      {
        path: "/confirm-booking",
        element: <Booking />,
      },
      {
        path: "/faq",
        element: <FAQ />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/terms-conditions",
        element: <Terms />,
      },
      {
        path: "/terms-conditions",
        element: <Terms />,
      },
      {
        path: "/privacy-policy",
        element: <Privacy />,
      },
      {
        path: "/create-account",
        element: <Register />,
      },
      {
        path: "/create-account/:ref_id",
        element: <Register />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/error",
        element: <ErrorPage />,
      },
      {
        path: "/err",
        element: <Errorboundary />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/earn-points",
        element: <EarnPoints />,
      },
      {
        path: "/contact-us",
        element: <ContactUs />,
      },
    ],
  },
  {
    path: "/user",
    element: <PrivateLayout />,
    children: [
      {
        path: "/user/account",
        element: <Account />,
      },
      {
        path: "/user/help-center",
        element: <HelpCenter />,
      },
      {
        path: "/user/contact",
        element: <Contact />,
      },
      {
        path: "/user/checkout",
        element: <Payment />,
      },
      {
        path: "/user/flight-booking-success/:ticketId",
        element: <Succes />,
      },
      {
        path: "/user/earn-points",
        element: <EarnPoints />,
      },
        
    ],
  },
];

const RoutesConfigs = () => {
  let element = useRoutes(publicRoutes);
  return element;
};
export default RoutesConfigs;
