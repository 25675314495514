import React from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
    },
    laptop: {
        breakpoint: { max: 1024, min: 768 },
        items: 2,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

const SkelSearch = () => {
    return (
        <SkeletonTheme>
            <div class="FlightSearchResultBoxSec">
                <div class="FlightSearchResultBox">
                    <div class="FlightSearchResultBoxFirstDtl">
                        <div class="FlightSearchResultBoxFirstDtlRow2">
                            <div class="FlightSearchResultBoxFirstDtlRow2Lftdtl">
                                <div class="FlightSearchRightSelectInpt">
                                    <Skeleton height={15} width={15} />
                                </div>
                                <div class="FlightSearchRightFlight">

                                    <Skeleton height={50} width={100} />

                                    <br />
                                    <div> <Skeleton height={10} width={100} /></div>
                                </div>

                                <div class="flightArrvlDprtMainBox">
                                    <div class="FlightRightDepartureBox">
                                        <span><Skeleton height={10} width={90} /></span>
                                        <Skeleton height={10} width={90} />
                                        <span><Skeleton height={10} width={90} /></span>
                                    </div>
                                    <div class="FlightSearchTtlDetail">
                                        <div class="FlightSearchTtlHour">
                                            <span><Skeleton height={10} width={90} /></span>
                                            <Skeleton height={10} width={90} />
                                            <span><Skeleton height={10} width={90} /></span>
                                        </div>
                                        <div ></div>
                                        <div class="FlightSearchTtlHour">
                                            <span><Skeleton height={10} width={90} /></span>
                                            <Skeleton height={10} width={90} />
                                            <span><Skeleton height={10} width={90} /></span>
                                        </div>
                                    </div>
                                    <div class="FlightRightDepartureBox text-end">
                                        <span><Skeleton height={10} width={90} /></span>
                                        <Skeleton height={10} width={90} />
                                        <span><Skeleton height={10} width={90} /></span>
                                    </div>
                                </div>
                                <div class="FlightSearchDtailBx">
                                    <div><Skeleton height={10} width={90} /></div>
                                    <Skeleton height={50} width={90} /> <i class="fa fa-angle-down"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="FlightSearchConfirmSection">
                        <div class="FlightSearchConfirmSectionRow1">
                            <div class="FlightSearchTtlAmount">
                                <strong><span><Skeleton height={10} width={90} /></span></strong>
                                <span class="totalAmount"><Skeleton height={10} width={90} /></span>
                            </div>
                            <Skeleton className="confirmSecBtn" height={30} width={100} />

                        </div>

                    </div>
                </div>
                <div class="FlightSearchResultBox">
                    <div class="FlightSearchResultBoxFirstDtl">
                        <div class="FlightSearchResultBoxFirstDtlRow2">
                            <div class="FlightSearchResultBoxFirstDtlRow2Lftdtl">
                                <div class="FlightSearchRightSelectInpt">
                                    <Skeleton height={15} width={15} />
                                </div>
                                <div class="FlightSearchRightFlight">

                                    <Skeleton height={50} width={100} />

                                    <br />
                                    <div> <Skeleton height={10} width={100} /></div>
                                </div>

                                <div class="flightArrvlDprtMainBox">
                                    <div class="FlightRightDepartureBox">
                                        <span><Skeleton height={10} width={90} /></span>
                                        <Skeleton height={10} width={90} />
                                        <span><Skeleton height={10} width={90} /></span>
                                    </div>
                                    <div class="FlightSearchTtlDetail">
                                        <div class="FlightSearchTtlHour">
                                            <span><Skeleton height={10} width={90} /></span>
                                            <Skeleton height={10} width={90} />
                                            <span><Skeleton height={10} width={90} /></span>
                                        </div>
                                        <div ></div>
                                        <div class="FlightSearchTtlHour">
                                            <span><Skeleton height={10} width={90} /></span>
                                            <Skeleton height={10} width={90} />
                                            <span><Skeleton height={10} width={90} /></span>
                                        </div>
                                    </div>
                                    <div class="FlightRightDepartureBox text-end">
                                        <span><Skeleton height={10} width={90} /></span>
                                        <Skeleton height={10} width={90} />
                                        <span><Skeleton height={10} width={90} /></span>
                                    </div>
                                </div>
                                <div class="FlightSearchDtailBx">
                                    <div><Skeleton height={10} width={90} /></div>
                                    <Skeleton height={50} width={90} /> <i class="fa fa-angle-down"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="FlightSearchConfirmSection">
                        <div class="FlightSearchConfirmSectionRow1">
                            <div class="FlightSearchTtlAmount">
                                <strong><span><Skeleton height={10} width={90} /></span></strong>
                                <span class="totalAmount"><Skeleton height={10} width={90} /></span>
                            </div>
                            <Skeleton className="confirmSecBtn" height={30} width={100} />

                        </div>

                    </div>
                </div>
                <div class="FlightSearchResultBox">
                    <div class="FlightSearchResultBoxFirstDtl">
                        <div class="FlightSearchResultBoxFirstDtlRow2">
                            <div class="FlightSearchResultBoxFirstDtlRow2Lftdtl">
                                <div class="FlightSearchRightSelectInpt">
                                    <Skeleton height={15} width={15} />
                                </div>
                                <div class="FlightSearchRightFlight">

                                    <Skeleton height={50} width={100} />

                                    <br />
                                    <div> <Skeleton height={10} width={100} /></div>
                                </div>

                                <div class="flightArrvlDprtMainBox">
                                    <div class="FlightRightDepartureBox">
                                        <span><Skeleton height={10} width={90} /></span>
                                        <Skeleton height={10} width={90} />
                                        <span><Skeleton height={10} width={90} /></span>
                                    </div>
                                    <div class="FlightSearchTtlDetail">
                                        <div class="FlightSearchTtlHour">
                                            <span><Skeleton height={10} width={90} /></span>
                                            <Skeleton height={10} width={90} />
                                            <span><Skeleton height={10} width={90} /></span>
                                        </div>
                                        <div ></div>
                                        <div class="FlightSearchTtlHour">
                                            <span><Skeleton height={10} width={90} /></span>
                                            <Skeleton height={10} width={90} />
                                            <span><Skeleton height={10} width={90} /></span>
                                        </div>
                                    </div>
                                    <div class="FlightRightDepartureBox text-end">
                                        <span><Skeleton height={10} width={90} /></span>
                                        <Skeleton height={10} width={90} />
                                        <span><Skeleton height={10} width={90} /></span>
                                    </div>
                                </div>
                                <div class="FlightSearchDtailBx">
                                    <div><Skeleton height={10} width={90} /></div>
                                    <Skeleton height={50} width={90} /> <i class="fa fa-angle-down"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="FlightSearchConfirmSection">
                        <div class="FlightSearchConfirmSectionRow1">
                            <div class="FlightSearchTtlAmount">
                                <strong><span><Skeleton height={10} width={90} /></span></strong>
                                <span class="totalAmount"><Skeleton height={10} width={90} /></span>
                            </div>
                            <Skeleton className="confirmSecBtn" height={30} width={100} />

                        </div>

                    </div>
                </div>
                <div class="FlightSearchResultBox">
                    <div class="FlightSearchResultBoxFirstDtl">
                        <div class="FlightSearchResultBoxFirstDtlRow2">
                            <div class="FlightSearchResultBoxFirstDtlRow2Lftdtl">
                                <div class="FlightSearchRightSelectInpt">
                                    <Skeleton height={15} width={15} />
                                </div>
                                <div class="FlightSearchRightFlight">

                                    <Skeleton height={50} width={100} />

                                    <br />
                                    <div> <Skeleton height={10} width={100} /></div>
                                </div>

                                <div class="flightArrvlDprtMainBox">
                                    <div class="FlightRightDepartureBox">
                                        <span><Skeleton height={10} width={90} /></span>
                                        <Skeleton height={10} width={90} />
                                        <span><Skeleton height={10} width={90} /></span>
                                    </div>
                                    <div class="FlightSearchTtlDetail">
                                        <div class="FlightSearchTtlHour">
                                            <span><Skeleton height={10} width={90} /></span>
                                            <Skeleton height={10} width={90} />
                                            <span><Skeleton height={10} width={90} /></span>
                                        </div>
                                        <div ></div>
                                        <div class="FlightSearchTtlHour">
                                            <span><Skeleton height={10} width={90} /></span>
                                            <Skeleton height={10} width={90} />
                                            <span><Skeleton height={10} width={90} /></span>
                                        </div>
                                    </div>
                                    <div class="FlightRightDepartureBox text-end">
                                        <span><Skeleton height={10} width={90} /></span>
                                        <Skeleton height={10} width={90} />
                                        <span><Skeleton height={10} width={90} /></span>
                                    </div>
                                </div>
                                <div class="FlightSearchDtailBx">
                                    <div><Skeleton height={10} width={90} /></div>
                                    <Skeleton height={50} width={90} /> <i class="fa fa-angle-down"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="FlightSearchConfirmSection">
                        <div class="FlightSearchConfirmSectionRow1">
                            <div class="FlightSearchTtlAmount">
                                <strong><span><Skeleton height={10} width={90} /></span></strong>
                                <span class="totalAmount"><Skeleton height={10} width={90} /></span>
                            </div>
                            <Skeleton className="confirmSecBtn" height={30} width={100} />
                        </div>
                    </div>
                </div>
                <div class="FlightSearchResultBox">
                    <div class="FlightSearchResultBoxFirstDtl">
                        <div class="FlightSearchResultBoxFirstDtlRow2">
                            <div class="FlightSearchResultBoxFirstDtlRow2Lftdtl">
                                <div class="FlightSearchRightSelectInpt">
                                    <Skeleton height={15} width={15} />
                                </div>
                                <div class="FlightSearchRightFlight">
                                    <Skeleton height={50} width={100} />
                                    <br />
                                    <div> <Skeleton height={10} width={100} /></div>
                                </div>

                                <div class="flightArrvlDprtMainBox">
                                    <div class="FlightRightDepartureBox">
                                        <span><Skeleton height={10} width={90} /></span>
                                        <Skeleton height={10} width={90} />
                                        <span><Skeleton height={10} width={90} /></span>
                                    </div>
                                    <div class="FlightSearchTtlDetail">
                                        <div class="FlightSearchTtlHour">
                                            <span><Skeleton height={10} width={90} /></span>
                                            <Skeleton height={10} width={90} />
                                            <span><Skeleton height={10} width={90} /></span>
                                        </div>
                                        <div ></div>
                                        <div class="FlightSearchTtlHour">
                                            <span><Skeleton height={10} width={90} /></span>
                                            <Skeleton height={10} width={90} />
                                            <span><Skeleton height={10} width={90} /></span>
                                        </div>
                                    </div>
                                    <div class="FlightRightDepartureBox text-end">
                                        <span><Skeleton height={10} width={90} /></span>
                                        <Skeleton height={10} width={90} />
                                        <span><Skeleton height={10} width={90} /></span>
                                    </div>
                                </div>
                                <div class="FlightSearchDtailBx">
                                    <div><Skeleton height={10} width={90} /></div>
                                    <Skeleton height={50} width={90} /> <i class="fa fa-angle-down"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="FlightSearchConfirmSection">
                        <div class="FlightSearchConfirmSectionRow1">
                            <div class="FlightSearchTtlAmount">
                                <strong><span><Skeleton height={10} width={90} /></span></strong>
                                <span class="totalAmount"><Skeleton height={10} width={90} /></span>
                            </div>
                            <Skeleton className="confirmSecBtn" height={30} width={100} />

                        </div>

                    </div>
                </div>
                <div class="FlightSearchResultBox">
                    <div class="FlightSearchResultBoxFirstDtl">
                        <div class="FlightSearchResultBoxFirstDtlRow2">
                            <div class="FlightSearchResultBoxFirstDtlRow2Lftdtl">
                                <div class="FlightSearchRightSelectInpt">
                                    <Skeleton height={15} width={15} />
                                </div>
                                <div class="FlightSearchRightFlight">

                                    <Skeleton height={50} width={100} />

                                    <br />
                                    <div> <Skeleton height={10} width={100} /></div>
                                </div>

                                <div class="flightArrvlDprtMainBox">
                                    <div class="FlightRightDepartureBox">
                                        <span><Skeleton height={10} width={90} /></span>
                                        <Skeleton height={10} width={90} />
                                        <span><Skeleton height={10} width={90} /></span>
                                    </div>
                                    <div class="FlightSearchTtlDetail">
                                        <div class="FlightSearchTtlHour">
                                            <span><Skeleton height={10} width={90} /></span>
                                            <Skeleton height={10} width={90} />
                                            <span><Skeleton height={10} width={90} /></span>
                                        </div>
                                        <div ></div>
                                        <div class="FlightSearchTtlHour">
                                            <span><Skeleton height={10} width={90} /></span>
                                            <Skeleton height={10} width={90} />
                                            <span><Skeleton height={10} width={90} /></span>
                                        </div>
                                    </div>
                                    <div class="FlightRightDepartureBox text-end">
                                        <span><Skeleton height={10} width={90} /></span>
                                        <Skeleton height={10} width={90} />
                                        <span><Skeleton height={10} width={90} /></span>
                                    </div>
                                </div>
                                <div class="FlightSearchDtailBx">
                                    <div><Skeleton height={10} width={90} /></div>
                                    <Skeleton height={50} width={90} /> <i class="fa fa-angle-down"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="FlightSearchConfirmSection">
                        <div class="FlightSearchConfirmSectionRow1">
                            <div class="FlightSearchTtlAmount">
                                <strong><span><Skeleton height={10} width={90} /></span></strong>
                                <span class="totalAmount"><Skeleton height={10} width={90} /></span>
                            </div>
                            <Skeleton className="confirmSecBtn" height={30} width={100} />

                        </div>

                    </div>
                </div>
            </div>
        </SkeletonTheme>
    );
};
export default SkelSearch;
