import React, { useState } from "react";
import LoadingBar from "react-top-loading-bar";
import planeImg  from "../../assets/images/plane.gif"

const FlightLoader = ({ isLoading, progress, setProgress }) => {
  return (
    <div class="FLightSearchLoaderPopupSection">
      <LoadingBar
        color="#00B8EE"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <div class="loader">
        <div class="wait"></div>
        {/* <div class="iata_code departure_city">CDG</div> */}
        <div class="plane">
          <img
            src={planeImg
            }
            class="plane-img"
          />
        </div>
        <div class="earth-wrapper">
          <div class="earth"></div>
        </div>
        {/* <div class="iata_code arrival_city">JFK</div> */}
      </div>
    </div>
  );
};
export default FlightLoader;
