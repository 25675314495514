import React from "react";
import facebook from "../assets/images/facebook.png";
import follow_image_3 from "../assets/images/twitter.png";
import follow_image_2 from "../assets/images/instagram.png";
import follow_image_4 from "../assets/images/ticktok.png";
import iData from "../assets/images/iata.png";
import Logo from "../assets/images/floght-coop-logo.png";
import { motion, AnimatePresence } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import footerimage from "../assets/images/footer_bg.jpg";
import { useSelector } from "react-redux";

const Footer = (props) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  return (
    <AnimatePresence>
      <motion.footer
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 50 }}
        transition={{ duration: 0.1 }}
        className="footerSection"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="footerBox">
                <div className="footerBoxHead">
                  <img src={Logo} alt="" />
                </div>
                <div className="footerBoxCnt">Follow Us</div>
                <div className="footerSocialSec">
                  {console.log("=========================================",props?.connectus)}
                  {props?.connectus?.map((link, index) => (
                    <a key={index} target="_blank" href={link?.url}>
                      <div className="footerSocialBox">
                        <img src={`${link.image}`} alt={link?.altText} />
                      </div>
                    </a>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footerBox">
                <div className="footerBoxHead">Book with us</div>
                <div className="footerBoxCnt">
                  <ul>
                    <a href="/">
                      <li>Find a flight</li>
                    </a>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footerBox">
                <div className="footerBoxHead">My flight booking</div>
                <div className="footerBoxCnt">
                  <ul>
                    <a href="/user/help-center">
                      <li>Help Center</li>
                    </a>
                    <a href="/faq">
                      <li>FAQs</li>
                    </a>
                    <a href="/contact-us">
                      <li>Contact Us</li>
                    </a>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="footerBox">
                <div className="footerBoxHead">Company</div>
                <div className="footerBoxCnt">
                <ul>
  {isLoggedIn ? (
    <a href="/user/earn-points">
      <li>Earn Points</li>
    </a>
  ) : (
    <a href="/earn-points">
      <li>Earn Points</li>
    </a>
  )}
  <a href="/terms-conditions">
    <li>Terms & Conditions</li>
  </a>
  <a href="/privacy-policy">
    <li>Privacy Policy</li>
  </a>
</ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footerCopyRight">
          <div className="container">
            <div className="footerCopyRightCnt">
              <div>Flight Booking © 2023 All rights reserved</div>
              {/* <div className="footerCopyRightIatLg">
                <img src={iData} alt="" />
              </div> */}
              {/* <div className="DesgnDevelop">Design & Developed by IOSS</div> */}
            </div>
          </div>
        </div>
      </motion.footer>
    </AnimatePresence>
  );
};
export default Footer;
