
import { useState, useEffect,React } from 'react';
// import '../../assets/css/common.css';
  import { ToastContainer, toast } from 'react-toastify';
//   import 'react-toastify/dist/ReactToastify.css';
import "../../assets/css/customToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlaneCircleCheck,
  faPlaneCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
const Toast = (props) => {

  const { show, position, message, variant, theme, dismiss } = props;
  useEffect(() => {
    if (show) {
      const toastConfig = {
        icon:
          variant === "error" ? (
            <FontAwesomeIcon
              icon={faPlaneCircleExclamation}
              fade
              size="2xl"
              style={{ color: theme == "colored" ? "#ffffff" : "#fa0000" }}
            />
          ) : (
            // <img src={Image} style={{height:70,width:60}}/>
            <FontAwesomeIcon icon={faPlaneCircleCheck} size="2xl" />
          ),
        toastId: 1,
        position: position ? position : "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        theme: theme ? theme : "light",
      };

      const toastId = toast[variant](message, toastConfig);

      if (dismiss) {
        setTimeout(() => {
          toast.dismiss(toastId);
          dismiss();
        }, 6000);
      }
    }
  }, [show, position, message, variant, theme, dismiss]);


      

  const containerStyle = {
    backgroundColor: "transparent",
    backgroundSize: "cover",
    width: "450px",
    wrap: "nowrap",
    height: "300px",
  };

  return (
    <div>
      {props.show && (
        <ToastContainer
          style={containerStyle}
          className="custom-toast-container"
          position="top-right"
          autoClose={8000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          //   rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      )}
    </div>
  );
};
export default Toast;
