export const getConditionsByPrice = (availableConditions) => {
  console.log("availableConditions", availableConditions);
  let newConditions = {};

  if (availableConditions) {
    const conditions = Object.entries(availableConditions)
      .map(([key, value]) => {
        if (
          availableConditions[key] != null &&
          availableConditions[key]["allowed"]
        ) {
          return {
            [key]: {
              penalty_amount: availableConditions[key]["penalty_amount"],
              currency: availableConditions[key]["penalty_currency"],
            },
          };
        }
      })
      .filter((condition) => condition); // Filter out undefined elements

    return conditions;
  }
  return {};
};
