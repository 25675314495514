import axios from "axios";

const axiosInstance = axios.create({
  //  baseURL: "http://192.168.10.40:5002/api/v1/",
  //  baseURL: "http://162.19.146.132:1114/api/v1/",
  //baseURL: "http://44.195.135.3:5000/api/v1/",
   //baseURL: "http://65.109.0.94:1118/api/v1/",
  // baseURL: "http://192.168.10.40:5005/api/v1/",
  baseURL: "http://flightapi.wappss.com/api/v1/",
  baseURL: "https://flightapi.wappss.com/api/v1/",
});
axiosInstance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token") ?? null;
    if (token) {
      config.headers.access_token = token;
      config.headers["auth_token"] = token;
    }
    config.headers["token"] = token;
    config.headers["x-auth-token"] = "30dafb6aaf2f9f47557d31c4adc2ccfe3086883a";
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
export default axiosInstance;
