import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
// import iocn1 from "../../assets/images/ico1.jpg";
import flight from'../../assets/images/flight_err.png'
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
function Errorboundary() {
    const navigate=useNavigate()
  return (
    <section className="homeFeatureSection" style={{marginTop:"4%"}}>
    <div className="container">

      <div className="homeFeatureBox">
      <div className="homeFeatureBoxIco">
                      <div style={{justifyContent:"center",display:"flex",alignItems:"center"}}>
                      <h3>An Error Occured</h3>
                   
                      </div>
                      <div className="homeFeatureBoxHead"style={{justifyContent:"center",display:"flex",alignItems:"center",flexDirection:"column",margin:'10%'}}>
                      <LazyLoadImage
                        alt="homeFeatureBoxCnt"
                        src={flight}
                        effect="blur"
                        width={100}
                        height={100}
                      />
                      <span style={{fontSize:14,color:"#999",textAlignLast:"center"}}>
          
                Please try again Later.We are fixing on it

                </span>
                  

                    </div>
                    <div style={{justifyContent:"center",display:"flex"}}>
                    <Button  onClick={()=>{navigate("/" )}}>Back to Home</Button>

                    </div>
                    </div>
                 
      <div className="homeFeatureBoxCnt">
                 
                  </div>


        </div></div></section>
  )
}

export default Errorboundary