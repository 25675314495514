import React from "react";
import Search from "../dashboard/Search";
import { isMobile } from "react-device-detect";

const SearchBanner = (props) => {
  return (
    <>
  
      <section className="searchBannersec"  style={{display:props.showMob === false&& isMobile && 'none'}}>
        <section className="" id="bookFlightSec" >
          <Search 
          {...props}
          decCountPassender={props.decCountPassender} 
          handleDatesChange={props.handleDatesChange} 
          searchInputChanges={props.searchInputChanges} 
          searchPlaceChanges={props.searchPlaceChanges} 
          isLoading={props.isLoading}
          options={props.options}
          fetchData={props.fetchData}
          searchSubmit = {props.searchSubmit}
          singleDatesChange={props.singleDatesChange}
          onSelectCabinClass={props.onSelectCabinClass}
          filter={props.filter}
          showMob = {props.showMob}
          setSearch={props.setSearch}
          closeButton={true}
       
          />
        </section>
      </section>
    </>
  );
};
export default SearchBanner;
