import React, { useState } from "react";
import "react-multi-carousel/lib/styles.css";
import { TimeConvertor } from "../../helpers/utills/TimeConvertor";
import moment from "moment";
import RightArrow from "../../assets/images/right_arrow.png";
import SkelSearch from "./SkelSearch";
import { Button } from "react-bootstrap";
import flightIcon from "../../assets/images/flight_ico.svg";
import { PassengersConvert } from "../../helpers/utills/PassengersConvert";
import { DocumentConvertor } from "../../helpers/utills/DocumentConvertor";
import Nodata from "../../components/common/Nodata";
import FlightDeatils from "./FlightDetails";

const Cheapest = ({
  flightDetails,
  ViewDeatils,
  isLoading,
  conFirmFlight,
  error,
  passengerDetails,
}) => {
  const [tab, setTab] = useState("flightInformBox");


  return (
    <div className="FlightSearchResultBoxSec">
      {isLoading || error ? (
        <SkelSearch />
      ) : (
        <>
        {flightDetails == 0 && <Nodata message="No Flights Found." />}
          {flightDetails?.map((obj, index) => {
              return (
                <div className="FlightSearchResultBox" key={index}>
                  <div className="FlightSearchResultBoxFirstDtl">
                    {obj.slices.map((slices, slindex) => {
                      return (
                        <>
                          <div
                            className="FlightSearchResultBoxFirstDtlRow1"
                            key={slindex}
                          >
                            <div className="FlightSearchResultBoxFirstDtlRow1LCDprt">
                              <div className="FlightResultDprtDtl">
                                <strong>{slices?.origin?.city_name}</strong>
                                <span>
                                  <img
                                    className="arrowIco"
                                    src={RightArrow}
                                    alt=""
                                  />
                                </span>
                                <strong>
                                  {slices?.destination?.city_name}
                                </strong>
                              </div>
                            </div>
                            <div>
                              <span className="mobHide">Departure on</span>{" "}
                              {moment(
                                slices.segments[slices.segments.length - 1]
                                  ?.departing_at
                              ).format("ddd MMM DD ")}
                            </div>
                          </div>
                          <div className="FlightSearchResultBoxFirstDtlRow2">
                            <div className="FlightSearchResultBoxFirstDtlRow2Lftdtl">
                              <div className="FlightSearchRightSelectInpt">
                                {/* <input type="checkbox" name="1" id="" /> */}
                              </div>
                              <div className="FlightSearchRightFlight">
                                <div className="FlightSearchRightFlightImg">
                                  <img
                                    src={obj?.owner?.logo_symbol_url}
                                    alt=""
                                  />
                                </div>
                                <span>{obj?.owner?.name}</span>
                              </div>

                              <div className="flightArrvlDprtMainBox">
                                <div className="FlightRightDepartureBox">
                                  <span>
                                    {moment(
                                      slices.segments[0]?.departing_at
                                    ).format("ddd MMM DD ")}
                                  </span>
                                  {moment(
                                    slices.segments[0]?.departing_at
                                  ).format("h:mm A")}
                                  <span>{slices?.origin?.name}</span>
                                </div>
                                <div className="FlightSearchTtlDetail">
                                  <div className="FlightSearchTtlHour">
                                    <span>
                                      <img
                                        src={flightIcon}
                                        style={{ width: "25px" }}
                                        alt=""
                                      />
                                    </span>
                                    {"duration" in slices && (
                                      <span>
                                        {TimeConvertor(slices?.duration)}
                                      </span>
                                    )}
                                    <span className="return_flight_ico">
                                      <img
                                        src={flightIcon}
                                        style={{ width: "25px" }}
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  <div className="FlightSearchTtlimg"></div>
                                  <div className="FlightSearchTtlHour">
                                    <span>{slices?.origin?.iata_code}</span>
                                    <span>
                                      {" "}
                                      {slices?.segments?.length == 1
                                        ? "Nonstop"
                                        : slices?.segments?.length -
                                          1 +
                                          " Stop"}
                                    </span>
                                    <span>
                                      {slices?.destination?.iata_code}
                                    </span>
                                  </div>
                                </div>
                                <div className="FlightRightDepartureBox text-end">
                                  <span>
                                    {moment(
                                      slices.segments[
                                        slices.segments.length - 1
                                      ]?.arriving_at
                                    ).format("ddd MMM DD ")}
                                  </span>
                                  {moment(
                                    slices.segments[slices.segments.length - 1]
                                      ?.arriving_at
                                  ).format("h:mm A")}
                                  <span>{slices?.destination?.name}</span>
                                </div>
                              </div>
                            </div>
                            <div className="FlightSearchDtailBx">
                              <span>Seats available</span>

                              <div
                            className="details-container"
                            onClick={() => ViewDeatils(slices)}
                          >
                            <a className="FlightSearchDtailBtn">
                              Details{" "}
                              <i
                                className={
                                  slices.show
                                    ? "fa fa-angle-down"
                                    : "fa fa-angle-up"
                                }
                              ></i>
                            </a>
                          </div>
                            </div>
                          </div>
                          <div></div>
                          {/* Mobile view Sctions  */}
                          <div className="FlightSearchResultBoxFirstDtlRow2Mob">
                            <div className="FlightSearchRightSelectInpt">
                              {/* <input type="checkbox" name="01" id="" /> */}
                            </div>
                            <div className="FlightSearchResultBoxFirstDtlRow2MobFlight">
                              <div className="FlightSearchRightFlight">
                                <div className="FlightSearchRightFlightImg">
                                  <img
                                    src={obj?.owner?.logo_symbol_url}
                                    alt=""
                                  />
                                </div>
                                <span>{obj?.owner?.name}</span>
                              </div>
                            </div>
                            <div className="FlightSearchResultBoxFirstDtlRow2MobTxtDtl">
                              <div className="FlightSearchResultBoxFirstDtlRow2MobTime">
                                <strong>
                                  {moment(
                                    slices.segments[0]?.arriving_at
                                  ).format("h:mm A")}
                                </strong>{" "}
                                -{" "}
                                <strong>
                                  {moment(
                                    slices.segments[slices.segments.length - 1]
                                      ?.departing_at
                                  ).format("h:mm A")}
                                </strong>
                              </div>
                              <div className="FlightSearchResultBoxFirstDtlRow2MobLoc">
                                <span>{slices?.origin?.iata_code}</span> -{" "}
                                <span>{slices?.destination?.iata_code}</span>
                              </div>
                            </div>
                          </div>
                          <div className="FlightSearchResultBoxFirstDtlRow2MobBtn">
                            <span>
                              <i className="fa-solid fa-diagram-project"></i>{" "}
                              {slices?.segments?.length == 1
                                ? "Nonstop"
                                : slices?.segments?.length - 1 + " Stop"}
                            </span>
                            <span>
                              <i className="fa-regular fa-clock"></i>{" "}
                              {TimeConvertor(slices?.duration)}
                            </span>
                            <div
                            className="details-container"
                            onClick={() => ViewDeatils(slices)}
                          >
                            <a className="FlightSearchDtailBtn">
                              Details{" "}
                              <i
                                className={
                                  slices.show
                                    ? "fa fa-angle-down"
                                    : "fa fa-angle-up"
                                }
                              ></i>
                            </a>
                          </div>
                          </div>
                          {slices.show && <FlightDeatils slices={slices} details={obj} passengerDetails={passengerDetails}/>}
                          {/* {slices.show && (
                            <>
                              <div class="flightSearchMoreDtl">
                                <div class="flightSarchinformationTabBtnSec">
                                  <div
                                    id="flightInfoBtn"
                                    class={
                                      tab == "flightInformBox"
                                        ? "flightSarchinformationTabBtn active"
                                        : "flightSarchinformationTabBtn"
                                    }
                                    onClick={() => setTab("flightInformBox")}
                                  >
                                    FLIGHT INFORMATION
                                  </div>
                                  <div
                                    id="fareDtailBtn"
                                    class={
                                      tab == "fareDetailsBox"
                                        ? "flightSarchinformationTabBtn active"
                                        : "flightSarchinformationTabBtn"
                                    }
                                    onClick={() => setTab("fareDetailsBox")}
                                  >
                                    FARE DETAILS
                                  </div>
                                  <div
                                    id="bagageRuleBtn"
                                    class={
                                      tab == "bagageDetailsBox"
                                        ? "flightSarchinformationTabBtn active"
                                        : "flightSarchinformationTabBtn"
                                    }
                                    onClick={() => setTab("bagageDetailsBox")}
                                  >
                                    BAGGAGE RULES
                                  </div>
                                  {obj.conditions?.refund_before_departure
                                    ?.allowed ||
                                    (obj.conditions?.change_before_departure
                                      ?.allowed && (
                                      <div
                                        id="cancelRuleBtn"
                                        class={
                                          tab == "cancelChargeBox"
                                            ? "flightSarchinformationTabBtn active"
                                            : "flightSarchinformationTabBtn"
                                        }
                                        onClick={() =>
                                          setTab("cancelChargeBox")
                                        }
                                      >
                                        CONDITIONS
                                      </div>
                                    ))}
                                </div>
                              </div>

                              <div
                                class="flightBtmDtlFlightInformbox"
                                id="flightInformBox"
                                style={{
                                  display:
                                    tab == "flightInformBox" ? "block" : "none",
                                }}
                              >
                                {slices.segments.map((seg, segindex) => {
                                  return (
                                    <div
                                      class="flightSearchDtlBtmSection"
                                      key={segindex}
                                    >
                                      <div class="flightSearchDtlBtmImgSec">
                                        <div class="flightSearchDtlBtmImg">
                                          <img
                                            src={
                                              seg?.operating_carrier
                                                ?.logo_symbol_url
                                            }
                                            alt=""
                                          />
                                        </div>
                                        <span>
                                          {seg?.operating_carrier?.name}
                                        </span>
                                      </div>
                                      <div class="flightSearchDtlBtmDprtArvl">
                                        <div class="FlightBtmDepartureBox">
                                          <span>
                                            {moment(seg?.departing_at).format(
                                              "ddd MMM DD "
                                            )}
                                          </span>
                                          {moment(seg?.departing_at).format(
                                            "h:mm A"
                                          )}
                                          <span>{seg?.origin.city_name}</span>
                                        </div>
                                        <div class="FlightDtlTtlDetail">
                                          <div class="FlightDtlTtlHour">
                                            <span>
                                              <img
                                                src={flightIcon}
                                                style={{ width: "25px" }}
                                                alt=""
                                              />
                                            </span>
                                            <strong>
                                              {TimeConvertor(seg?.duration)}
                                            </strong>
                                            <span class="return_flight_ico">
                                              <img
                                                src={flightIcon}
                                                style={{ width: "25px" }}
                                                alt=""
                                              />
                                            </span>
                                          </div>
                                          <div class="FlightDtlTtlflightDrtn">
                                            Flight Duration
                                          </div>
                                        </div>
                                        <div class="FlightBtmDepartureBox text-end">
                                          <span>
                                            {moment(seg?.arriving_at).format(
                                              "ddd MMM DD "
                                            )}
                                          </span>
                                          {moment(seg?.arriving_at).format(
                                            "h:mm A"
                                          )}
                                          <span>
                                            {seg?.destination.city_name}
                                          </span>
                                        </div>
                                      </div>
                                      {seg.stops.length > 0 &&
                                        seg?.stops?.map((stObj, stIndex) => {
                                          return (
                                            <div
                                              class="layouvrBox"
                                              key={stIndex}
                                            >
                                              {TimeConvertor(stObj?.duration)}
                                            </div>
                                          );
                                        })}
                                    </div>
                                  );
                                })}
                              </div>
                              <div
                                class="flightBtmDtlFlightInformbox"
                                id="fareDetailsBox"
                                style={{
                                  display:
                                    tab == "fareDetailsBox" ? "block" : "none",
                                }}
                              >
                                <table class="faredtlTable">
                                  <tr>
                                    <td>Base Fare (1 Adult)</td>
                                    <td>$ {obj?.base_amount}</td>
                                  </tr>
                                  <tr>
                                    <td>Taxes and Fees (1 Adult)</td>
                                    <td>$ {obj?.tax_amount}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Total Fare (1 Adult) </strong>
                                    </td>
                                    <td>
                                      <strong>$ {obj?.total_amount}</strong>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                              <div
                                class="flightBtmDtlFlightInformbox bagageDtl"
                                id="bagageDetailsBox"
                                style={{
                                  display:
                                    tab == "bagageDetailsBox"
                                      ? "block"
                                      : "none",
                                }}
                              >
                                {slices.segments.map((seg, segindex) => {
                                  return seg.passengers.map(
                                    (passData, passIndex) => {
                                      return (
                                        <>
                                          {console.log("passDataDetails", seg)}
                                          <div
                                            class="FlightSearchRightFlight"
                                            key={passIndex}
                                          >
                                            <div class="FlightSearchRightFlightImg">
                                              <img
                                                src={
                                                  seg?.operating_carrier
                                                    ?.logo_symbol_url
                                                }
                                                alt=""
                                              />
                                            </div>
                                            <span
                                              style={{
                                                color: "black",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              {seg.origin.iata_city_code +
                                                "-" +
                                                seg.destination.iata_city_code}
                                            </span>
                                          </div>
                                          {passData.baggages.length > 0 ? (
                                            <table class="faredtlTable">
                                              <tr>
                                                <td>Baggage Type</td>
                                                {passData.baggages.map(
                                                  (baggData, baggIndex) => {
                                                    return (
                                                      <td key={baggIndex}>
                                                        {
                                                          DocumentConvertor[
                                                            baggData.type
                                                          ]
                                                        }
                                                      </td>
                                                    );
                                                  }
                                                )}
                                              </tr>
                                              <tr>
                                                <td>
                                                  {PassengersConvert(
                                                    passengerDetails.filter(
                                                      (obj) =>
                                                        obj.id ==
                                                        passData.passenger_id
                                                    )[0]?.type,
                                                    1
                                                  )}
                                                </td>
                                                {passData.baggages.map(
                                                  (baggData, baggIndex) => {
                                                    return (
                                                      <>
                                                        <td key={baggIndex}>
                                                          {baggData.type ==
                                                            "checked" &&
                                                          baggData.quantity >
                                                            0 ? (
                                                            <p>
                                                              {" "}
                                                              <i
                                                                class="fa fa-suitcase"
                                                                aria-hidden="true"
                                                              ></i>{" "}
                                                              Includes{" "}
                                                              {
                                                                baggData.quantity
                                                              }{" "}
                                                              checked bag
                                                            </p>
                                                          ) : baggData.type ==
                                                              "carry_on" &&
                                                            baggData.quantity >
                                                              0 ? (
                                                            <p>
                                                              {" "}
                                                              <i class="fas fa-suitcase-rolling"></i>{" "}
                                                              Includes{" "}
                                                              {
                                                                baggData.quantity
                                                              }{" "}
                                                              carry on bag
                                                            </p>
                                                          ) : (
                                                            <p>
                                                              <i class="fas fa-suitcase-rolling"></i>{" "}
                                                              Includes{" "}
                                                              {
                                                                DocumentConvertor[
                                                                  baggData.type
                                                                ]
                                                              }
                                                            </p>
                                                          )}
                                                        </td>
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </tr>
                                            </table>
                                          ) : (
                                            <p></p>
                                          )}

                                          <div
                                            style={{
                                              float: "right",
                                              padding: "10px",
                                              display: "flex",
                                            }}
                                          >
                                            <p style={{ fontSize: "12px" }}>
                                              <i
                                                class="fa-solid fa-chair mr-2"
                                                title="Seat Available"
                                                style={{
                                                  color: passData.cabin
                                                    .amenities?.seat
                                                    ? "balck"
                                                    : "grey",
                                                  cursor: "pointer",
                                                }}
                                              ></i>{" "}
                                              <i
                                                class="fa-solid fa-wifi mr-2"
                                                title="Wifi Available"
                                                style={{
                                                  color: passData.cabin
                                                    .amenities?.wifi?.available
                                                    ? "balck"
                                                    : "grey",
                                                  cursor: "pointer",
                                                }}
                                              ></i>{" "}
                                              <i
                                                class="fa-solid fa-plug"
                                                title="Power Available"
                                                style={{
                                                  color: passData.cabin
                                                    .amenities?.power?.available
                                                    ? "balck"
                                                    : "grey",
                                                  cursor: "pointer",
                                                }}
                                              ></i>
                                            </p>
                                          </div>
                                        </>
                                      );
                                    }
                                  );
                                })}
                              </div>
                              <div
                                class="flightBtmDtlFlightInformbox bagageDtl"
                                id="cancelChargeBox"
                                style={{
                                  display:
                                    tab == "cancelChargeBox" ? "block" : "none",
                                }}
                              >
                                <div class="row">
                                  {obj.conditions?.refund_before_departure
                                    ?.allowed ? (
                                    <div
                                      className={`${
                                        obj.conditions?.refund_before_departure
                                          ?.allowed &&
                                        obj.conditions?.change_before_departure
                                          ?.allowed
                                          ? "col-md-6"
                                          : "col-md-12"
                                      }`}
                                    >
                                      <strong>Cancellation Charges</strong>
                                      <table class="faredtlTable">
                                        <tr>
                                          <td>Penalty Currency</td>
                                          <td>
                                            <strong>
                                              {
                                                obj.conditions
                                                  ?.refund_before_departure
                                                  ?.penalty_currency
                                              }
                                            </strong>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Penalty Amount</td>
                                          <td>
                                            <strong>
                                              {
                                                obj.conditions
                                                  ?.refund_before_departure
                                                  ?.penalty_amount
                                              }{" "}
                                              {
                                                obj.conditions
                                                  ?.refund_before_departure
                                                  ?.penalty_currency
                                              }
                                            </strong>
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                  ) : (
                                    <></>
                                  )}

                                  {obj.conditions?.change_before_departure
                                    ?.allowed && (
                                    <div
                                      className={`${
                                        obj.conditions?.refund_before_departure
                                          ?.allowed &&
                                        obj.conditions?.change_before_departure
                                          ?.allowed
                                          ? "col-md-6"
                                          : "col-md-12"
                                      }`}
                                    >
                                      <strong>Reschedule Charges</strong>
                                      <table class="faredtlTable">
                                        <tr>
                                          <td>Penalty Currency</td>
                                          <td>
                                            <strong>
                                              {obj.conditions
                                                ?.change_before_departure
                                                ?.penalty_currency
                                                ? obj.conditions
                                                    ?.change_before_departure
                                                    ?.penalty_currency
                                                : "Not available"}
                                            </strong>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Penalty Amount</td>
                                          <td>
                                            <strong>
                                              {obj.conditions
                                                ?.change_before_departure
                                                ?.penalty_amount
                                                ? obj.conditions
                                                    ?.change_before_departure
                                                    ?.penalty_amount
                                                : 0}{" "}
                                              {
                                                obj.conditions
                                                  ?.change_before_departure
                                                  ?.penalty_currency
                                              }
                                            </strong>
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                  )}
                                </div>
                              </div>

                             
                            </>
                          )} */}
                        </>
                      );
                    })}
                    <div className="FlightSearchConfirmSection">
                      <div className="FlightSearchConfirmSectionRow1">
                        <div className="FlightSearchTtlAmount">
                          <strong>
                            {obj?.base_currency} $ {obj?.total_amount}
                            <span className="perperson"></span>
                          </strong>
                          {/* <span className="totalAmount">
                            {obj?.base_currency} {obj?.total_amount} total
                          </span> */}
                        </div>
                        <Button
                          type="submit"
                          className="confirmSecBtn"
                          onClick={(e) => conFirmFlight(e, obj.id)}
                        >
                          {obj.slices.length > 1
                            ? "Select Flights"
                            : "Select Flight"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          
        </>
      )}
    </div>
  );
};
export default Cheapest;
