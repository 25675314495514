import React from 'react';
import './Spinner.css';

const Spinner = () => {
  return (
  

 <section class="dots-container">
<div class="dot"></div>
<div class="dot"></div>
<div class="dot"></div>
{/* <div class="dot"></div>
<div class="dot"></div> */}
</section> 


  );
};

export default Spinner;