import React, { Children, useState, useEffect } from "react";
import "react-select-search/style.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "react-dates/initialize";
import { DateRangePicker, SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import { useForm } from "react-hook-form";
import moment from "moment";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Button } from "react-bootstrap";
import { PassengersConvert } from "../../helpers/utills/PassengersConvert";
import { useLocation } from "react-router-dom";
import flightIcon from "../../assets/images/flight_ico.svg";
import Toaster from "../../components/common/Toaster";
import { motion, AnimatePresence } from "framer-motion";
import { isMobile } from "react-device-detect";

const Search = (props) => {
  const [focusedInput, setFocusedInput] = useState(null);
  const [focusedInputSingle, setFocusedInputSingle] = useState(null);
  const animatedComponents = makeAnimated();
  const cookies = new Cookies();
  const location = useLocation();
  const [alert, setalert] = useState({ status: false, type: "", message: "" });
  const cook = cookies.get("search");
  const {
    register,
    handleSubmit,
    onErrors,
    setValue,
    clearErrors,
    trigger,

    formState: { errors, isSubmitted, isSubmitting, isFocused },
  } = useForm({});
  const {
    register: oneWayForm,
    handleSubmit: oneWayFormSubmit,
    setValue: setoneWayFormValue,
    formState: { errors: error },
  } = useForm({});
  const navigate = useNavigate();

  const [state, setState] = useState({
    activeTab: cook?.activeTab ? cook.activeTab : "roundTripTab",
    roundTripTab: {
      passengers: [
        {
          title: "Adults",
          count: 1,
          name: "adult",
          display_name: "(Age 12+)",
          disabled: false,
        },
        {
          title: "Children",
          count: 0,
          name: "child",
          display_name: "(Age 2-17)",
          disabled: false,
        },
        {
          title: "Infants",
          count: 0,
          name: "infant_without_seat",
          display_name: "(Under 2)",
          disabled: false,
        },
      ],
      calender: {
        departDate: moment(new Date()),
        returnDate: moment(new Date().getTime() + 2 * 24 * 60 * 60 * 1000),
      },
      search: {
        leaveFrom: "",
        leaveTo: "",
        selectedFrom: "",
        selectedFromFocused: false,
        selectedTo: "",
        selectedToFocused: false,
      },
      serachLoader: false,
      selectedpassengers: {
        type: [
          {
            title: "Adults",
            count: 1,
            type: "adult",
          },
        ],
        class: "economy",
      },
    },
    onewayTripTab: {
      passengers: [
        {
          title: "Adults",
          count: 1,
          name: "adult",
          display_name: "(Age 12+)",
        },
        {
          title: "Children",
          count: 0,
          name: "child",
          display_name: "(Age 2-17)",
        },
        {
          title: "Infants",
          count: 0,
          name: "infant_without_seat",
          display_name: "(Under 2)",
        },
      ],
      calender: {
        departDate: moment(new Date()),
      },
      search: {
        leaveFrom: "",
        leaveTo: "",
        selectedFrom: "",
        selectedTo: "",
      },
      serachLoader: false,
      selectedpassengers: {
        type: [
          {
            title: "Adults",
            count: 1,
            type: "adult",
          },
        ],
        class: "economy",
      },
    },
  });

  const [focused, SetFocued] = useState();
  //decCountPassender

  //counter change
  const decCountPassender = (name, type, key) => {
    let data = [];
    const passengers = state[key].passengers;
    const inFantsCounts = passengers.find(
      (obj) => obj.name === "infant_without_seat"
    )?.count;
    const adultsCount = passengers.find((obj) => obj.name === "adult")?.count;
    const childCount = passengers.find((obj) => obj.name === "child")?.count;

    const newData = passengers.map((item) => {
      if (name === item.name) {
        let count = item.count;
        if (type === "inc") {
          if (
            !(item.name === "infant_without_seat" && count === adultsCount) &&
            passenCount() < 9
          ) {
            count++;
          }
        } else if (count > 0) {
          if (
            !(
              (item.name === "adult" && item.count === 0) ||
              (item.name === "adult" && count === inFantsCounts)
            )
          ) {
            count--;
          }
        }
        return {
          ...item,
          count,
        };
      }
      return item;
    });

    newData.forEach((item) => {
      if (item.count > 0) {
        data.push({ type: item.name, count: item.count, title: item.title });
      }
    });

    setState((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        passengers: newData,
        selectedpassengers: {
          ...prev[key].selectedpassengers,
          type: data,
        },
      },
    }));
  };

  //diable based on the count
  useEffect(() => {
    let newData = state[state.activeTab].passengers.map((item) => {
      const childCount = state[state.activeTab].passengers.find(
        (obj) => obj.name == "child"
      ).count;
      const adultCount = state[state.activeTab].passengers.find(
        (obj) => obj.name == "adult"
      ).count;
      console.log(childCount, adultCount);
      if (item.name == "adult") {
        return {
          ...item,
          disabled: item.count == 1 || childCount == 0 ? true : false,
        };
      }
      if (item.name == "child") {
        return {
          ...item,
          disabled: item.count == 1 || adultCount == 0 ? true : false,
        };
      } else return item;
    });

    // setState((prev) => ({
    //     ...prev,
    //     [key]: {
    //       ...prev[key],
    //       passengers: newData,
    //     },
    //   }));
  }, [state[state.activeTab].passengers]);

  //display cabin calss
  const onSelectCabinClass = (e, key) => {
    setState((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        selectedpassengers: {
          ...prev[key].selectedpassengers,
          class: e.target.value,
        },
      },
    }));
    //trigger("cabinclass")
  };

  //Date changes
  const handleDatesChange = ({ startDate, endDate }) => {
    setState((prev) => ({
      ...prev,
      roundTripTab: {
        ...prev.roundTripTab,
        calender: {
          ...prev.calender,
          departDate: startDate,
          returnDate: endDate,
        },
      },
    }));
    trigger(["startDate", "endDate"]);
  };

  // singleDatpicker change
  const singleDatesChange = (date) => {
    setState((prev) => ({
      ...prev,
      onewayTripTab: {
        ...prev.onewayTripTab,
        calender: {
          ...prev.calender,
          departDate: date,
        },
      },
    }));
  };
  //searchInputCahges
  const searchInputChanges = (e) => {};

  useEffect(() => {
    if (location.pathname == "/search") {
      applyFilter(state.activeTab);
    }
  }, [props.filter]);

  useEffect(() => {
    console.log("state.activeTab",state.activeTab);
    if (cook) {
      const { selectedpassengers, search, calender, passengers } =
        cook[cook.activeTab];

      setState((prev) => ({
        ...prev,
        activeTab: cook.activeTab,
        [cook.activeTab]: {
          ...prev[cook.activeTab],
          passengers: passengers,
          selectedpassengers: {
            ...prev[cook.activeTab].selectedpassengers,
            type: selectedpassengers.type,
            class: selectedpassengers.class,
          },
          search: {
            ...prev[cook.activeTab].search,
            selectedFrom: search.selectedFrom,
            selectedTo: search.selectedTo,
          },

          calender: {
            ...prev[cook.activeTab].calender,
            departDate:
              moment(calender.departDate) < new Date()
                ? moment(new Date())
                : moment(calender.departDate),
            returnDate:
              moment(calender.returnDate) < new Date()
                ? moment(new Date().getTime() + 2 * 24 * 60 * 60 * 1000)
                : moment(calender.returnDate),
          },
        },
      }));
      //set values to the form checkings
      setValue("cabinclass", selectedpassengers.class);
      setValue("selectedFrom", search.selectedFrom);
      setValue("selectedTo", search.selectedTo);
      setValue("startDate", calender.departDate);
      setValue("endDate", calender.returnDate);
      setoneWayFormValue("cabinclass", selectedpassengers.class);
      setoneWayFormValue("selectedFrom", search.selectedFrom);
      setoneWayFormValue("selectedTo", search.selectedTo);
      setoneWayFormValue("date", calender.departDate);
    } else {
      const { selectedpassengers, search, calender, passengers } =
        state[state.activeTab];
      //set values to the form checkings
      setValue("cabinclass", selectedpassengers.class);
      setValue("selectedFrom", search.selectedFrom);
      setValue("selectedTo", search.selectedTo);
      setValue("startDate", calender.departDate);
      setValue("endDate", calender.returnDate);
      setoneWayFormValue("cabinclass", selectedpassengers.class);
      setoneWayFormValue("selectedFrom", search.selectedFrom);
      setoneWayFormValue("selectedTo", search.selectedTo);
      setoneWayFormValue("date", calender.departDate);
    }
  }, [props.filter, state.activeTab]);

  //searchPlaceChanges
  const searchPlaceChanges = (e, key, variable) => {
    setState((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        search: {
          ...prev[key].search,
          [variable]: e,
        },
      },
    }));
    trigger(variable);
  };
  const applyFilter = (key) => {
    if (cook) {
      let state = cook;
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 7);
      cookies.set("search", state, { path: "/", expires: expirationDate });
      const { selectedFrom, selectedTo } = cook[key]?.search;
      const { departDate, returnDate } = cook[key]?.calender;
      const { selectedpassengers } = cook[key];

      let payload = {};
      const slices = [
        {
          origin: selectedFrom?.code,
          destination: selectedTo?.code,
          departure_date:
            moment(departDate) < new Date()
              ? moment(new Date()).format("YYYY-MM-DD")
              : moment(departDate).format("YYYY-MM-DD"),
        },
      ];
      if (key === "roundTripTab") {
        slices.push({
          origin: selectedTo?.code,
          destination: selectedFrom?.code,
          departure_date:
            moment(returnDate) < new Date()
              ? moment(new Date()).format("YYYY-MM-DD")
              : moment(returnDate).format("YYYY-MM-DD"),
        });
      }
      payload["slices"] = slices;
      payload["passengers"] = selectedpassengers?.type;
      payload["cabin_class"] = selectedpassengers?.class;
      payload["return_offers"] = true;
      payload["filter"] = props.filter;
      navigate("/search", { state: { message: payload } });
    }
  };

  const searhSubmit = (key) => {
    // props.clearFilter(); //claer all the filters on searhc
    console.log(state);
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 7);
    cookies.set("search", state, { path: "/", expires: expirationDate });
    const { selectedFrom, selectedTo } = state[key]?.search;
    const { departDate, returnDate } = state[key]?.calender;
    const { selectedpassengers } = state[key];

    let payload = {};
    const slices = [
      {
        origin: selectedFrom?.code,
        destination: selectedTo?.code,
        departure_date:
          moment(departDate) < new Date()
            ? moment(new Date()).format("YYYY-MM-DD")
            : moment(departDate).format("YYYY-MM-DD"),
      },
    ];
    if (key === "roundTripTab") {
      slices.push({
        origin: selectedTo?.code,
        destination: selectedFrom?.code,
        departure_date:
          moment(returnDate) < new Date()
            ? moment(new Date()).format("YYYY-MM-DD")
            : moment(returnDate).format("YYYY-MM-DD"),
      });
    }
    payload["slices"] = slices;
    payload["passengers"] = selectedpassengers?.type;
    payload["cabin_class"] = selectedpassengers?.class;
    payload["return_offers"] = true;
    payload["filter"] = props.filter;
    navigate("/search", { state: { message: payload } });
  };

  const customStyles = {
    control: (defaultStyles) => ({
      ...defaultStyles,
      border: "none",
      boxShadow: "none",
      color: "red",
    }),
    option: {
      color: state.isSelected ? "#fff" : state.isFocused ? "#fff" : "black",
      backgroundColor: state.isSelected
        ? "#2563eb"
        : state.isFocused
        ? "#2563eb"
        : "#fff",
      marginBottom: "2px",
      borderBottom: "1px solid #e1e8ee",
      padding: "10px",
      cursor: state.isFocused ? "pointer" : "default",
      display: "flex",
    },
    optionImage: {
      marginRight: "8px",
      width: "25px",
      height: "25px",
      borderRadius: "50%",
    },
    optionSubtitle: {
      fontSize: "12px",
      color: "#999",
    },
    indicatorSeparator: (defaultStyles) => ({
      ...defaultStyles,
      display: "none",
    }),
    dropdownIndicator: (defaultStyles) => ({
      ...defaultStyles,
      display: "none",
    }),
  };

  let cabinClassName = {
    economy: "Economy",
    premium_economy: "Premium Economy",
    business: "Business",
    first: "First Class",
  };
  const onSubmit = ({ ...rest }) => {
    props?.setSearch&&props?.setSearch()
    if (passenCount() < 0) {
      setalert({
        status: true,
        type: "error",
        message: "Please select passengers count",
      });

      // toast.error();
    } else if (passenCount() > 9) {
      // toast.error("Maximum 9 passengers allowed");
      setalert({
        status: true,
        type: "error",
        message: "Maximum 9 passengers allowed",
      });
    } else {
      searhSubmit("roundTripTab");
    }
  };
  const oneWaySubnmit = (data) => {
    console.log("passs", passenCount);
    if (passenCount() < 0) {
      setalert({
        status: true,
        type: "error",
        message: "Please select passengers count",
      });
    } else if (passenCount() > 9) {
      setalert({
        status: true,
        type: "error",
        message: "Maximum 9 passengers allowed",
      });
      // toast.error("Maximum 9 passengers allowed");
    } else {
      searhSubmit("onewayTripTab");
    }
  };

  const onError = (errors, e) => {
    console.log("errors", error);
  };
  const onOnewayErros = (errors, e) => {
    console.log("errors", error);
  };

  const handleSelectedTab = (tab) => {
    console.log("tab",tab);
    cookies.remove("search");
    setState((prev) => ({
      ...prev,
      activeTab: tab,
    }));
    console.log(tab);
  };
  const handleFocus = (key) => {
    SetFocued(key);
  };
  const passenCount = (tab) => {
    const childCount = state[state.activeTab].passengers.reduce(
      (acc, passenger) => {
        return acc + passenger.count;
      },
      0
    );
    console.log(childCount);
    return childCount;
  };

  const { search, calender, selectedpassengers, passengers } =
    state[state.activeTab];

  const modalVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  const toggleSearch = () => {
    const { search } = state[state.activeTab];
    setState((prev) => ({
      ...prev,
      [state.activeTab]: {
        ...prev[state.activeTab],
        search: {
          ...prev[state.activeTab].search,
          selectedFrom: search.selectedTo,
          selectedTo: search.selectedFrom,
        },
      },
    }));
  };

  const clearSelection = (e, key, variable) => {
    setState((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        search: {
          ...prev[key].search,
          [variable]: "",
        },
      },
    }));
  };
  const Dismisstoast = () => {
    setalert({ status: false, message: "" });
    return true;
  };

  const Option = ({ innerProps, label, data, isFocused, isSelected }) => {
    const backgroundColor = isSelected || isFocused ? "#2563eb" : "#fff";
    const color = isSelected || isFocused ? "#fff" : "black";
    const cursor = isFocused ? "pointer" : "default";

    return (
      <div
        {...innerProps}
        style={{ ...customStyles.option, backgroundColor, color, cursor }}
      >
        <img src={flightIcon} alt="" style={customStyles.optionImage} />
        <div>
          {data.city_name && (
            <div>{data.city_name + "(" + data.code + ") "}</div>
          )}
          <div style={customStyles.optionSubtitle}>{label}</div>
        </div>
      </div>
    );
  };
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 0.9 }}
        className="container"
       id="bookFlightSec"
      >
        <div className="bookingFomrBox">
          {props?.closeButton && (
            <Button className="filterCloseBtn" onClick={props?.setSearch}>
              <i class="fa fa-close"></i>
            </Button>
          )}
          {!isMobile &&
           <div className="bookingFomrBoxHeadSc">
            {/* <div className="bookingFomrBoxHeadtxt">Search & Book Flights</div> */}
          </div>
          }
         
          <Tabs
          activeKey={state.activeTab}
            defaultActiveKey={"roundTripTab"}
            id="uncontrolled-tab-example"
            className="bookingFomrBoxHeabtns"
            onSelect={handleSelectedTab}
          >
            <Toaster
              show={alert.status}
              variant={alert.type}
              message={alert.message}
              position="top-right"
              theme="dark"
              dismiss={Dismisstoast}
            />
            <Tab eventKey="roundTripTab" title="Roundtrip" id="roundtrpBtn">
              <div className="searchBookBtmRow">
                <form
                  className="searchBookBtmRow"
                  onSubmit={handleSubmit(onSubmit, onError, "roundTripTab")}
                >
                  <div className="searchBookBtmLocation">
                    <div className="searchBookBtmBox">
                      <div className="styled-input">
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          id="leave"
                          isLoading={props.isLoading}
                          isError={props.isError}
                          components={{ Option }}
                          backspaceRemovesValue={true}
                          isClearable
                          getOptionLabel={(e) => e.name}
                          getOptionValue={(e) => e.name}
                          loadOptions={(inputValue, callback) => {
                            props.fetchData(inputValue).then((data) => {
                              callback(data.data);
                            });
                          }}
                          onFocus={() => handleFocus("rndSelectedFrom")}
                          name="selectedFrom"
                          onInputChange={(value) =>
                            searchInputChanges(
                              "roundTripTab",
                              "leaveFrom",
                              value
                            )
                          }
                          placeholder=""
                          styles={customStyles}
                          value={search?.selectedFrom}
                          {...register("selectedFrom", {
                            required: true,
                          })}
                          onChange={(e) => {
                            setValue("selectedFrom", e);
                            searchPlaceChanges(
                              e,
                              "roundTripTab",
                              "selectedFrom"
                            );
                          }}
                          isOptionDisabled={(option) =>
                            option.id == search?.selectedTo?.id
                          }
                        />

                        <label
                          className={
                            errors.selectedFrom && focused
                              ? "hasErrors labelHeight"
                              : focused == "rndSelectedFrom" ||
                                search?.selectedFrom ||
                                props.focusedInput
                              ? "labelHeight"
                              : ""
                          }
                        >
                          Leaving from
                        </label>
                      </div>
                    </div>
                    <div className="searchToggleBtnDv" onClick={toggleSearch}>
                      <div className="searchToggleBtn">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="repeat_24px">
                            <path
                              id="icon/av/repeat_24px"
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M14.1667 5.83334H5.83333V9.16667H4.16667V4.16667H14.1667V1.66667L17.5 5.00001L14.1667 8.33334V5.83334ZM5.83333 14.1667H14.1667V10.8333H15.8333V15.8333H5.83333V18.3333L2.5 15L5.83333 11.6667V14.1667Z"
                              fill="black"
                              fillOpacity="0.54"
                            />
                          </g>
                        </svg>
                      </div>
                    </div>
                    <div className="searchBookBtmBox">
                      <div className="styled-input">
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          components={{ Option }}
                          getOptionLabel={(e) => e.name}
                          getOptionValue={(e) => e.name}
                          loadOptions={(inputValue, callback) => {
                            props.fetchData(inputValue).then((data) => {
                              callback(data.data);
                            });
                          }}
                          onInputChange={(value) =>
                            searchInputChanges("roundTripTab", "leaveTo", value)
                          }
                          name="selectedTo"
                          placeholder=""
                          value={search?.selectedTo}
                          styles={customStyles}
                          backspaceRemovesValue={true}
                          isClearable
                          onFocus={() => handleFocus("rndselectedTo")}
                          {...register("selectedTo", {
                            required: true,
                            message: "Leaving from is required",
                          })}
                          onChange={(e) => {
                            setValue("selectedTo", e);
                            searchPlaceChanges(e, "roundTripTab", "selectedTo");
                          }}
                          isOptionDisabled={(option) =>
                            option.id == search?.selectedFrom?.id
                          }
                        />
                        <span></span>
                        <label
                          className={
                            errors.selectedTo && focused
                              ? "hasErrors labelHeight"
                              : focused == "rndselectedTo" || search?.selectedTo
                              ? "labelHeight"
                              : ""
                          }
                        >
                          Going To
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="searchBookDateBox">
                    <label
                      className={
                        errors.startDate || errors.endDate
                          ? "hasErrors"
                          : "searchBookBtmName"
                      }
                    >
                      Depart - Return
                    </label>
                    <DateRangePicker
                      startDate={
                        calender?.departDate ? calender?.departDate : null
                      }
                      startDateId="tata-start-date"
                      endDate={
                        calender?.returnDate ? calender?.returnDate : null
                      }
                      endDateId="tata-end-date"
                      onDatesChange={(event) => {
                        setValue("startDate", event.startDate);
                        setValue("endDate", event.endDate);
                        handleDatesChange(event);
                      }}
                      {...register("startDate", { required: true })}
                      {...register("endDate", { required: true })}
                      focusedInput={focusedInput}
                      onFocusChange={(focusedInput) =>
                        setFocusedInput(focusedInput)
                      }
                      small={true}
                      keepOpenOnDateSelect = {true}
                      hideKeyboardShortcutsPanel
                    />
                  </div>
                  <div className="searchBookDateBox dropdown passengerSelectBox">
                    <label
                      className={
                        errors.cabinclass ? "hasErrors" : "searchBookBtmName"
                      }
                    >
                      Passengers
                    </label>
                    <a
                      className="searchBookBtmBtn  dropdown-toggle"
                      href="#"
                      data-bs-auto-close="outside"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {selectedpassengers?.type.map((obj, index) => {
                        console.log("selectedpassengers",selectedpassengers);
                        return (
                          <span key={index}>
                            {obj.count} -{" "}
                            {PassengersConvert(obj.type, obj.count)},{" "}
                          </span>
                        );
                      })}{" "}
                      <span>{cabinClassName[selectedpassengers?.class]}</span>
                    </a>
                    <ul className="dropdown-menu">
                      <h5>Passengers</h5>
                      {state.roundTripTab.passengers.map((obj, index) => {
                        return (
                          <li key={index}>
                            <div className="passengerCountAddBox">
                              <div>
                                {obj.title}
                                <span>{obj.display_name}</span>
                              </div>

                              <div className="passengerCountBx">
                                <div
                                  className={`passengerCountbtn  ${
                                    obj.disabled && "disabled"
                                  }`}
                                  onClick={() =>
                                    decCountPassender(
                                      obj.name,
                                      "dec",
                                      "roundTripTab"
                                    )
                                  }
                                >
                                  -
                                </div>
                                <input
                                  value={obj.count}
                                  type="text"
                                  name={obj.name}
                                />
                                <div
                                  className={`passengerCountbtn`}
                                  onClick={() =>
                                    decCountPassender(
                                      obj.name,
                                      "inc",
                                      "roundTripTab"
                                    )
                                  }
                                >
                                  +
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                       {passenCount()==0 && (
                        <div style={{ fontSize: "12px", color: "red" }}>
                          Err:Add Passenger count
                        </div>
                      )}
                      {passenCount() >= 9 && (
                        <div style={{ fontSize: "12px", color: "red" }}>
                          Err:Maximum 9 passengers allowed
                        </div>
                      )}
                      <li>
                        <select
                          name="cabinclass"
                          id="cabinclass"
                          className="form-control FlightClassBook"
                          value={selectedpassengers.class}
                          {...register("cabinclass", { required: true })}
                          onChange={(e) => {
                            onSelectCabinClass(e, "roundTripTab");
                            // setValue("cabinclass", e);
                          }}
                        >
                          <option value="" selected>
                            Select Cabin class
                          </option>
                          <option value="economy">Economy</option>
                          <option value="premium_economy">
                            Premium Economy
                          </option>
                          <option value="business">Business</option>
                          <option value="first">First Class</option>
                        </select>
                      </li>
                    </ul>
                  </div>
                  <button type="submit" className="searchBookBoxBtn">
                    <i class="fa fa-search" aria-hidden="true"></i>Search
                  </button>
                </form>
              </div>
            </Tab>
            <Tab eventKey="onewayTripTab" title="One-way" id="multiCityBtn">
              <div className="searchBookBtmRow">
                <form
                  className="searchBookBtmRow"
                  onSubmit={oneWayFormSubmit(oneWaySubnmit, onOnewayErros)}
                >
                  <div className="searchBookBtmLocation">
                    <div className="searchBookBtmBox">
                      <div className="styled-input">
                        <AsyncSelect
                          isClearable
                          cacheOptions
                          defaultOptions
                          hideSelectedOptions={true}
                          isLoading={props.isLoading}
                          isError={props.isError}
                          components={{ Option }}
                          getOptionLabel={(e) => e?.name}
                          getOptionValue={(e) => e?.name}
                          onFocus={() => handleFocus("oneSelectedFrom")}
                          isOptionDisabled={(option) =>
                            option.id == search?.selectedTo?.id
                          }
                          loadOptions={(inputValue, callback) => {
                            props.fetchData(inputValue).then((data) => {
                              callback(data.data);
                            });
                          }}
                          onInputChange={(value) =>
                            searchInputChanges(
                              "onewayTripTab",
                              "leaveFrom",
                              value
                            )
                          }
                          name="selectedFrom"
                          // onChange={(value) => searchPlaceChanges('onewayTripTab', 'selectedFrom', value)}
                          {...oneWayForm("selectedFrom", {
                            required: true,
                          })}
                          value={search?.selectedFrom}
                          onChange={(e) => {
                            setoneWayFormValue("selectedFrom", e?.name);
                            searchPlaceChanges(
                              e,
                              "onewayTripTab",
                              "selectedFrom"
                            );
                          }}
                          placeholder=""
                          styles={customStyles}
                        />
                        {console.log(errors.selectedFrom && focused)}
                        <label
                          className={
                            `${
                              errors.selectedFrom && focused ? "hasErrors " : ""
                            }` +
                            `${
                              focused === "oneSelectedFrom" ||
                              search?.selectedFrom ||
                              props.focusedInput
                                ? "labelHeight"
                                : ""
                            }`
                          }
                        >
                          Leaving from
                        </label>
                      </div>
                    </div>
                    <div className="searchToggleBtnDv" onClick={toggleSearch}>
                      <div className="searchToggleBtn">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="repeat_24px">
                            <path
                              id="icon/av/repeat_24px"
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M14.1667 5.83334H5.83333V9.16667H4.16667V4.16667H14.1667V1.66667L17.5 5.00001L14.1667 8.33334V5.83334ZM5.83333 14.1667H14.1667V10.8333H15.8333V15.8333H5.83333V18.3333L2.5 15L5.83333 11.6667V14.1667Z"
                              fill="black"
                              fillOpacity="0.54"
                            />
                          </g>
                        </svg>
                      </div>
                    </div>
                    <div className="searchBookBtmBox">
                      <div className="styled-input">
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          isLoading={props.isLoading}
                          isError={props.isError}
                          components={{ Option }}
                          getOptionLabel={(e) => e.name}
                          getOptionValue={(e) => e.name}
                          loadOptions={(inputValue, callback) => {
                            props.fetchData(inputValue).then((data) => {
                              callback(data.data);
                            });
                          }}
                          backspaceRemovesValue={true}
                          isClearable
                          value={search?.selectedTo}
                          onFocus={() => handleFocus("oneSelectedTo")}
                          onInputChange={(value) =>
                            searchInputChanges(
                              "onewayTripTab",
                              "leaveTo",
                              value
                            )
                          }
                          name="selectedTo"
                          {...oneWayForm("selectedTo", {
                            required: true,
                          })}
                          isOptionDisabled={(option) =>
                            option.id == search?.selectedFrom?.id
                          }
                          onChange={(e) => {
                            setoneWayFormValue("selectedTo", e?.name);
                            searchPlaceChanges(
                              e,
                              "onewayTripTab",
                              "selectedTo"
                            );
                          }}
                          placeholder=""
                          styles={customStyles}
                        />
                        <label
                          className={
                            errors.selectedFrom && focused
                              ? "hasErrors labelHeight"
                              : focused == "oneSelectedTo" || search?.selectedTo
                              ? "labelHeight"
                              : ""
                          }
                        >
                          Going To
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="searchBookDateBox">
                    <label
                      className={error.date ? "hasErrors" : "searchBookBtmName"}
                    >
                      Departure Date
                    </label>
                    <SingleDatePicker
                      date={calender?.departDate}
                      focused={focusedInputSingle?.focused}
                      onFocusChange={(focusedInput) =>
                        setFocusedInputSingle(focusedInput)
                      }
                      className="searchBookBtmInput"
                      id="your_unique_id"
                      small={true}
                      placeholder=""
                      name="date"
                      onDateChange={(event) => {
                        setoneWayFormValue("date", event);
                        singleDatesChange(event);
                      }}
                      {...oneWayForm("date", { required: true })}
                    />
                  </div>
                  <div className="searchBookDateBox dropdown passengerSelectBox">
                    <label
                      className={
                        error.cabinclass ? "hasErrors" : "searchBookBtmName"
                      }
                    >
                      Passengers
                    </label>
                    <a
                      className="searchBookBtmBtn  dropdown-toggle"
                      href="#"
                      data-bs-auto-close="outside"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {selectedpassengers?.type.map((obj, index) => {
                        return (
                          <span key={index}>
                            {obj.count} -
                            {PassengersConvert(obj.type, obj.count)},{" "}
                          </span>
                        );
                      })}{" "}
                      <span>{cabinClassName[selectedpassengers?.class]}</span>
                    </a>
                    <ul className="dropdown-menu">
                      <h5>Passengers</h5>
                      {passengers.map((obj) => {
                        return (
                          <li>
                            <div className="passengerCountAddBox">
                              <div>
                                {obj.title}
                                <span>{obj.display_name}</span>
                              </div>

                              <div className="passengerCountBx">
                                <div
                                  className="passengerCountbtn"
                                  onClick={() =>
                                    decCountPassender(
                                      obj.name,
                                      "dec",
                                      "onewayTripTab"
                                    )
                                  }
                                >
                                  -
                                </div>
                                <input
                                  value={obj.count}
                                  type="text"
                                  name={obj.name}
                                />
                                <div
                                  className="passengerCountbtn"
                                  onClick={() =>
                                    decCountPassender(
                                      obj.name,
                                      "inc",
                                      "onewayTripTab"
                                    )
                                  }
                                >
                                  +
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                      <li>
                        <select
                          name="cabinclass"
                          id=""
                          className="form-control FlightClassBook"
                          {...oneWayForm("cabinclass", { required: true })}
                          onChange={(e) => {
                            onSelectCabinClass(e, "onewayTripTab");
                            setValue("cabinclass", e);
                          }}
                        >
                          <option value="" selected>
                            Select Cabin class
                          </option>
                          <option value="economy">Economy</option>
                          <option value="premium_economy">
                            Premium Economy
                          </option>
                          <option value="business">Business</option>
                          <option value="first">First Class</option>
                        </select>
                      </li>
                    </ul>
                  </div>
                  <button type="submit" className="searchBookBoxBtn">
                    <i class="fa fa-search" aria-hidden="true"></i>Search
                  </button>
                </form>
              </div>
            </Tab>
          </Tabs>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
export default Search;
