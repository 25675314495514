import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
// import iocn1 from "../../assets/images/ico1.jpg";
import flight from'../../assets/images/nodata.png'
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
function Nodata(props) {
  return (
    <section className="homeFeatureSection" style={{marginTop:"4%"}}>
    <div className="container">

      <div >
      <div className="homeFeatureBoxIco">
                      <div style={{justifyContent:"center",display:"flex",alignItems:"center"}}>
                      <h3>
                        {props?.message}
                      </h3>
                   
                      </div>
                      <div className="homeFeatureBoxHead"style={{justifyContent:"center",display:"flex",alignItems:"center",flexDirection:"column",margin:'2%'}}>
                      <LazyLoadImage
                        alt="homeFeatureBoxCnt"
                        src={flight}
                        effect="blur"
                        width={80}
                        height={80}
                      />
                      <span style={{fontSize:14,color:"#999",textAlignLast:"center"}}>
          

                </span>
                  

                    </div>
                    <div style={{justifyContent:"center",display:"flex"}}>

                    </div>
                    </div>
                 
      <div className="homeFeatureBoxCnt">
                 
                  </div>


        </div></div></section>
  )
}

export default Nodata