import { useMutation} from "@tanstack/react-query";
import loginService from "../../service/login";


export const useLogin = () =>{
    return useMutation(loginService.loginAccount);

}

export const useCheckLogin= () =>{
    return useMutation(loginService.checkAccount);

}
