import React, { useState, useEffect } from "react";
import airarabia from "../../assets/images/airarabia.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SkelBrand from "./SkelBrand";
import { motion, AnimatePresence, useViewportScroll } from "framer-motion";
import { isMobile } from "react-device-detect";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  laptop: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Brand = ({
  flightDeatils,
  isLoading,
  error,
  filter,
  addToFilter,
  deviceType,
}) => {
  const { airlines } = filter;

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;

    return isMobile
      ? currentSlide === flightDeatils.length - 1 && (
          <div
            className="carousel-button-group"
            style={{ position: "absolute", right: 0 }}
          >
            <button
              className="round-button"
              onClick={() =>
                goToSlide(
                  currentSlide === flightDeatils.length ?? currentSlide === 0
                )
              }
            >
              <i class="fa fa-angle-double-left" aria-hidden="true"></i>
            </button>
          </div>
        )
      : currentSlide === flightDeatils.length - 5 && (
          <div
            className="carousel-button-group"
            style={{ position: "absolute", right: 0 }}
          >
            <button className="round-button" onClick={() => goToSlide(0)}>
              <i className="fa fa-angle-double-left" aria-hidden="true"></i>
            </button>
          </div>
        );
  };

  if (isLoading || error) {
    return <SkelBrand />;
  } else {
    return (
      <>
        <section className="searchPageFlightSelectSection">
          <div className="container">
            <div className="searchPageFlightSelectSec">
              <div className="owl-carousel owl-theme">
                <Carousel
                  responsive={responsive}
                  swipeable={true}
                  draggable={true}
                  customButtonGroup={<ButtonGroup />}
                >
                  {flightDeatils?.map((obj, index) => {
                    return (
                      <div
                        className="searchPageitem item"
                        key={index}
                        style={{ width: "239.2px", marginRight: "20px" }}
                      >
                        <label
                          htmlFor={`checkbox-${obj.name}`}
                          className="FlightSelectLabel"
                        >
                          <div className="searchPageFlightbox">
                            <input
                              id={`checkbox-${obj.name}`}
                              className="FlightSelectFltr"
                              type="checkbox"
                              name={obj.name}
                              value={obj.name}
                              onChange={(e) => addToFilter(e, "ailrlines")}
                            />
                            <div className="FlightSelectCnt">
                              <img src={obj?.logo_symbol_url} alt="" />
                              <span>{obj?.name}</span>
                              <span> $ {obj?.base_amount}</span>
                            </div>
                          </div>
                        </label>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
};
export default Brand;
