import API from "../../api/interceptor";

const getFlightbyId = async (queryParms) => {
  return await API.post(`/duffel/select-offer/${queryParms}`);
};
const selelctFlight = async (queryParms) => {
  return await API.post(`/duffel/select-flight?query=${JSON.stringify(queryParms)}`);
};
const postPassengers = async (payload) => {
  return await API.post(`/duffel/book-ticket/${payload.offer_id}`,payload);
};
const getPassengers = async () => {
  return await API.get(`/user/get-passengers`);
};
const getPassengersById= async (id) => {
  return await API.get(`/user/get-single-passenger-detail/${id}`);
};

const applyOnDiscounts= async (payload) => {
  return await API.post(`/duffel/apply-discount`,payload);
};
const getFlightDeatils= async (payload) => {
  return await API.get(`/duffel/get-selected-flight-details/${payload}`);
};


const bookingService = {
  getFlightbyId,
  selelctFlight,
  postPassengers,
  getPassengers,
  getPassengersById,
  applyOnDiscounts,
  getFlightDeatils
};

export default bookingService;
