import React, { useState, useRef } from "react";
import BannerImage from "../assets/images/floght-coop-logo.png";
import { Link } from 'react-router-dom';

import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { logout } from "../store/authSlice";
import { useLocation } from "react-router-dom";

const NavBar = () => {
  const user = useSelector((state) => state.auth.user);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [colorChange, setColorchange] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const location = useLocation();
  const navRef = useRef(null); //navbarColorChange
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(false);
    } else {
      setColorchange(true);
    }
  };

  const navigateToHome = () => {
    cookies.remove("search");
    navigate("/");
  };

  //eventListertoChngeNavbaronscroll
  window.addEventListener("scroll", changeNavbarColor);
  const logOut = () => {
    localStorage.removeItem("token");
    dispatch(logout());
  };
  const onclickHelp = () => {
    if (isLoggedIn) {
      navigate("/user/help-center");
    } else {
      navigate("/login", {
        state: { from: "/user/help-center", message: "id" },
      });
    }
  };
  const onAccountClick = () => {
    navigate("/create-account", { state: { from: "/", message: "id" } });
  };
  const onSignInClik = () => {
    navigate("/login", { state: { from: "/", message: "id" } });
  };



  const handleLinkClick = () => {
    if (isLoggedIn) {
      navigate("/user/earn-points");
    } else {
      navigate("/earn-points");
    }
   
  };
  return (
    <motion.header
      className={colorChange ? "topMainHead" : "topMainHead fixed-header"}
      initial={{ opacity: 1, top: 0 }}
      animate={{
        y: window.scrollY >= 80 ? -10 : 1,
      }}
      transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.8 }}
      id="navbar"
    >
      <div className="container">
        <div className="row d-flex  align-items-center">
          <div className="col-md-4">
            <div className="logoTopSection">
              <Button
                style={{ background: "none", border: "none" }}
                onClick={navigateToHome}
              >
                <img src={BannerImage} alt="" />
                <img className="stickyLogo" src='http://192.168.20.91/flight-project/src/assets/images/logo-dark.png' alt="" />
              </Button>
            </div>
          </div>
          <div className="col-md-8 mobMenuFull">
            <nav className="navbar navbar-expand-lg " ref={navRef}>
              <div className="container-fluid">
                <a className="navbar-brand" href="#"></a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse justify-content-end"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav mb-2 mb-lg-0">
                    <li className="nav-item">
                      <Button
                        className="nav-link"
                        onClick={() => {
                          if (navRef.current) {
                            navRef.current.classList.remove("show"); 
                          }
                          onclickHelp(); 
                        }}
                        to={isLoggedIn ? "/user/help-center" : "/login"}
                      >
                        Help Center
                      </Button>
                      {/* <a className="nav-link" href="earn-point.html" >Earn Points</a> */}
                    </li>

                    <li className="nav-item">
                      {/* <Link
                        className="nav-link"
                        to="/earn-points"
                        onClick={handleLinkClick}
                      >
                        Earn Points
                      </Link>  */}
                      <Button
                        className="nav-link"
                        onClick={() => {
                          if (navRef.current) {
                            navRef.current.classList.remove("show"); 
                          }
                          handleLinkClick();
                        }}
                        >Earn Points</Button>
                    </li>
                    
                    {isLoggedIn ? (
                      <>
                        <li className="nav-item dropdown topLoginAccLnk">
                          <a
                            className="nav-link accountLoginBoxTop dropdown-toggle"
                            role="button"
                            data-bs-auto-close="outside"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            href="#"
                          >
                            {user}
                          </a>
                          <ul className="dropdown-menu">
                            <p>
                              {" "}
                              Go to account details to get your referral code and to view your points balance
                            </p>
                            <Link className="topSigninBtn" to="/user/account">
                              Account Details
                            </Link>
                            {/* <a href="#" className="topSigninBtn">
                          Sign In
                        </a> */}
                            <div className="topCreateAccBtn">
                              <Button className="topSigninBtn" onClick={logOut}>
                                Sign Out
                              </Button>
                            </div>
                          </ul>
                        </li>
                      </>
                    ) : (
                      <>
                        <li className="nav-item dropdown topLoginAccLnk">
                          <a
                            className="nav-link accountLoginBoxTop dropdown-toggle"
                            role="button"
                            data-bs-auto-close="outside"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            href="#"
                          >
                            Sign In
                          </a>
                          <ul className="dropdown-menu">
                            <p>
                              {" "}
                              Create an account to refer people and earn points
                              to redeem for future flights.
                            </p>
                            <Button
                              className="topSigninBtn"
                              onClick={onSignInClik}
                            >
                              Sign In
                            </Button>
                            {/* <a href="#" className="topSigninBtn">
                          Sign In
                        </a> */}
                            <div
                              style={{
                                justifyContent: "center",
                                display: "flex",
                              }}
                            >
                              <Button
                                style={{
                                  background: "none",
                                  border: "none",
                                  color: "black",
                                }}
                                className=""
                                onClick={onAccountClick}
                              >
                                Create a free account
                              </Button>
                            </div>
                          </ul>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </motion.header>
  );
};
export default NavBar;
