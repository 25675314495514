import React, { useState } from "react";
import LoadingBar from "react-top-loading-bar";
import planeImg from "../../assets/images/plane.gif";
import { TimeConvertor } from "../../helpers/utills/TimeConvertor";
import moment from "moment";
import { getConditionsByPrice } from "../../lib/getConditionsByPrice";
import { getPassengersByCount } from "../../lib/getPassengersByCount";
import {getAminitiesBasedOnType} from "../../lib/getAminitiesBasedOnType"
import TabDetails from "./TabDetails";
import FlightIcon from "../../assets/images/flight_ico.svg"


const FlightDetails = ({ slices ,details,passengerDetails}) => {
  const conditionsData =  getConditionsByPrice(details.conditions);
  const passengersCount =  getPassengersByCount(details.passengers);


  return (
    <div class="flightSearchMoreDtl">
      <div class="flight_more_dtl_box_new">
        <div class="flight_more_dtl_box_cnt">
          <div class="flight_more_dtl_box_left_sec">
            <div class="flight_more_dtl_box_left_head">Flight Details</div>
            <div class="flight_more_dtl_box_left_cnt">
              {slices.segments.map((slicObj, sliIndex) => (
                <>
                <div class="flight_more_dtl_box_lft_row" key={sliIndex}>
                  <div class="flight_more_dtl_box_lft_row_head">
                    <div class="flight_more_dtl_box_lft_row_cnt1">
                      <span class="flight_mr_dtl_img">
                        <img
                          src={slicObj?.operating_carrier?.logo_symbol_url}
                          alt=""
                        />
                      </span>
                      <span class="flight_mr_dtl_head">
                        {slicObj?.operating_carrier?.name} ({slicObj?.passengers[0]?.cabin_class_marketing_name})
                      </span>
                    </div>
                    <div class="flight_more_dtl_box_lft_row_cnt2">
                      {Object.entries(getAminitiesBasedOnType(slicObj.passengers)).map(([key,value])=>{
                        return (
                         <span class="flight_more_dtl_box_lft_row_cnt2_ico">
                        <i class={value['icon']} style={{'color':value['color']}}></i>
                      </span>
                        )
                      })}
                    </div>
                  </div>
                  <div class="flightSearchDtlBtmDprtArvl">
                    <div class="FlightBtmDepartureBox">
                      {slicObj?.origin?.city_name}

                      <br />

                      {moment(slicObj?.departing_at).format("h:mm A")}
                      <span>
                        {" "}
                        {moment(slicObj?.departing_at).format("ddd MMM DD ")}
                      </span>
                      <span>
                        {" "}
                        {slicObj?.origin?.city_name} Terminal{" "}
                        {slicObj.origin_terminal}
                      </span>
                    </div>

                    <div class="FlightDtlTtlDetail">
                      <div class="FlightDtlTtlHour">
                        <span>
                          <img
                            src={FlightIcon}
                            style={{ width: "25px" }}
                            alt=""
                          />
                        </span>
                        <strong> {TimeConvertor(slicObj?.duration)}</strong>
                        <span class="return_flight_ico">
                          <img
                            src={FlightIcon}
                            style={{ width: "25px" }}
                            alt=""
                          />
                        </span>
                      </div>
                      <div class="FlightDtlTtlflightDrtn">Flight Duration</div>
                    </div>

                    <div class="FlightBtmDepartureBox text-end">
                      {slicObj?.destination.city_name}
                      <br />
                      {moment(slicObj?.arriving_at).format("h:mm A")}
                      <span>
                        {" "}
                        {moment(slicObj?.arriving_at).format("ddd MMM DD ")}
                      </span>
                      <span>
                        {slicObj?.destination?.city_name} Terminal{" "}
                        {slicObj.destination_terminal}
                      </span>
                    </div>
                  </div>
                </div>
                
                </>
                
              ))}
            </div>
          </div>
          <TabDetails slices={slices} details={details} passengerDetails={passengerDetails} conditionsData={conditionsData}/>
          {/* <div class="flight_more_dtl_box_right_sec">
            <div class="flight_more_dtl_box_right_tab_sec">
              <a id="flight_more_btn1" class="active" href="#/">
                Fare Details
              </a>
              <a id="flight_more_btn2" href="#/">
                Baggage Rules
              </a>
              <a id="flight_more_btn3" href="#/">
                Condition
              </a>
            </div>
            <div class="flight_more_dtl_box_right_tab_cnt1" id="faredtail">
              <div class="flight_more_dtl_box_right_tab_cnt1_lst">
                <span>Base Fare ({passengersCount} Passenger) </span>
                <span> $ {base_amount}</span>
              </div>
              <div class="flight_more_dtl_box_right_tab_cnt1_lst">
                <span>Taxes and Fees ({passengersCount} Passenger)</span>
                <span>$ {tax_amount}</span>
              </div>
              <div class="flight_more_dtl_box_right_tab_cnt1_lst">
                <span>Total Fare ({passengersCount} Passenger) </span>
                <span>$ {total_amount} </span>
              </div>
            </div>

            <div
              class="flight_more_dtl_box_right_tab_cnt1"
              id="baggagerule"
              style={{ display: "none" }}
            >
              <div class="flight_more_dtl_box_right_tab_cnt1_2nd">
                <span>
                  <i class="fa-solid fa-suitcase"></i>
                </span>
                Includes 1 checked bag
              </div>
              <div class="flight_more_dtl_box_right_tab_cnt1_2nd">
                <span>
                  <i class="fa-solid fa-suitcase-rolling"></i>
                </span>
                Includes 1 carry on bag
              </div>
            </div>

            <div
              class="flight_more_dtl_box_right_tab_cnt1"
              id="condition"
              style={{ display: "none" }}
            >
              <div class="flight_more_dtl_box_right_tab_cnt1_2nd">
                <span>
                  <i class="fa-solid fa-plane-circle-check"></i>
                </span>
                Fully Changeable
              </div>
              <div class="flight_more_dtl_box_right_tab_cnt1_2nd">
                <span>
                  <i class="fa-solid fa-money-bill"></i>
                </span>
                Fully Refundable
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default FlightDetails;
