
export const PassengersConvert = (noun, count) => {
    let passengersData = {
        child: "Child",
        adult: "Adult",
        infant_without_seat: "Infant"
    }
    if (count == 1) {
        return passengersData[noun]
    }
    if (noun =='child') {
        return 'Children'; // Irregular plural form
    }
    else {
        return passengersData[noun] + "s"
    }
}