import { log } from "util";

export const TimeConvertor = (time) => {
 
    let wordData;
    const match = time.match(/P(\d+D)?T(\d+H)?(\d+M)?/);
    const days = match && typeof match[1] !== 'undefined'? parseInt(match[1]) : 0;
    const hours = match && typeof match[2] !== 'undefined' ? parseInt(match[2]) : 0;
    const minutes = match && typeof match[3] !== 'undefined'? parseInt(match[3]) : 0;
    if (days && hours && minutes) {
        wordData = days + "D"+" " + hours + "H " + " " + minutes + "M";
    }
    else if (days && hours) {
        wordData = days + "D" + hours + "H";
    }
    else if (days && minutes) {
        wordData = days + "D" + " " + minutes + "M";
    }
    else if (days) {
        wordData = days + "D";
    }
    else if (hours && minutes) {
        wordData = hours + "H" + " " + minutes + "M";
    }
    else if (hours) {
        wordData = hours +" "+ "H";
    }
    else {
        wordData = minutes + "M";
    }
    return wordData
}



