import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import RoutesConfigs from "./routes";
import {
  QueryClient,
  QueryClientProvider,
  QueryCache,
} from "@tanstack/react-query";
import { Provider } from "react-redux";
import { store } from "./store";
import { Wrapper } from "./routes/Wrapper";

const queryClient = new QueryClient({

});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Wrapper>
        {/* <Toaster
          position="top-right"
          reverseOrder={true}
           gutter={24} 
          toastOptions={{    
            duration: 3000,
            iconTheme: {
              primary: "red",
              secondary: "white",
            },
            success: {
              style: {
                background: "green",  
                height:50
              },
            },
            error: {
              style: {
                background: "red",
                height:50
              },
            },
            role: "status",
            ariaLive: "polite",
            color:"white"
            
          }}
        /> */}
        <RoutesConfigs />
         </Wrapper>
      </BrowserRouter>
    </QueryClientProvider>
  </Provider>
);

reportWebVitals();
