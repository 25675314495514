import API from "../../api/interceptor";

const SearchPlace = async (queryParms) => {
  return await API.get(`duffel/search-place?query=${queryParms}`);
};
const getTicketsonSerach = async (queryParms) => {
  return await API.get(`duffel/flight-search?query=${JSON.stringify(queryParms)}`);
};
const getFLightsbyFilter = async (queryParms) => {
  return await API.post(`duffel/flight-search?query=${JSON.stringify(queryParms)}`);
};
const dashboardContent = async () => {
  return await API.get(`common/home`);
};

const addContactForm = async (payload) => {
  console.log(payload,"payload");
  return await API.post(`common/send-mail`,payload);
}
const dashbaordService = {
  SearchPlace,
  getTicketsonSerach,
  dashboardContent,
  addContactForm
};

export default dashbaordService;
