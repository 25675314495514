import React from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
    },
    laptop: {
        breakpoint: { max: 1024, min: 768 },
        items: 2,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

const SkelBrand = () => {
    return (
        <SkeletonTheme>
            <section className="searchPageFlightSelectSection">
                <div className="container">
                    <div className="searchPageFlightSelectSec">
                        <div className="owl-carousel owl-theme">
                            <Carousel
                                responsive={responsive}
                                swipeable={true}
                                draggable={true}
                                 >
                                <Skeleton className="searchPageitem item" style={{ "width": "239.2px", "marginRight": "20px" }} height={70} />
                                <Skeleton className="searchPageitem item" style={{ "width": "239.2px", "marginRight": "20px" }} height={70} />
                                <Skeleton className="searchPageitem item" style={{ "width": "239.2px", "marginRight": "20px" }} height={70} />
                                <Skeleton className="searchPageitem item" style={{ "width": "239.2px", "marginRight": "20px" }} height={70} />
                                <Skeleton className="searchPageitem item" style={{ "width": "239.2px", "marginRight": "20px" }} height={70} />
                                <Skeleton className="searchPageitem item" style={{ "width": "239.2px", "marginRight": "20px" }} height={70} />
                                <Skeleton className="searchPageitem item" style={{ "width": "239.2px", "marginRight": "20px" }} height={70} />
                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>
        </SkeletonTheme>
    );
};
export default SkelBrand;
