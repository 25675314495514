import React, { useState, useEffect } from "react";
import Search from "../dashboard/Search";
import moment from "moment";
import RightArrow from "../../assets/images/right_arrow.png";
import { TimeConvertor } from "../../helpers/utills/TimeConvertor";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Cheapest, FlightLoader, Quickest } from "./";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSlectedFlight } from "../../hooks/bookings/useBookings";
import earlymorning from "../../assets/images/earlymorning.png";
import afternoon from "../../assets/images/afternoon.png";
import morning from "../../assets/images/morning.png";
import evening from "../../assets/images/evening.png";
import { toast } from "react-hot-toast";
import Toaster from "../../components/common/Toaster";
import moments from "moment-timezone";

const SearchView = (props) => {
  const location = useLocation();
  const [alert, setalert] = useState({ status: false, type: "", message: "" });

  const tabFilters = {
    Recommended: "recommended",
    Cheapest: "cheapest",
    Quickest: "quickest",
  };

  const { mutate, isError, isSuccess, isLoading, error, data, reset } =
    useSlectedFlight();

  const [state, setState] = useState(props);
  const navigate = useNavigate();
  const [tabs, setTabs] = useState([
    {
      key: "Recommended",
      id: "Recommended",
      active: true,
    },
    {
      key: "Cheapest",
      id: "Cheapest",
      active: false,
    },
    {
      key: "Quickest",
      id: "Quickest",
      active: false,
    },
  ]);
  const ViewDeatils = (slice) => {
    console.log("_______sli8ce", slice);
    state?.data?.flightDetails.forEach((element, index) => {
      const newSlices = element.slices.map((slices) => {
        if (slices.id == slice.id) {
          return {
            ...slices,
            show: !slice.show,
          };
        } else return slices;
      });
      element.slices = newSlices;
      setState({
        ...state,
        data: {
          ...state.data,
          ...element,
        },
      });
    });
  };
  const Dismisstoast = () => {
    setalert({ status: false, message: "" });
    return true;
  };

  const findDisabled = (start, end) => {
  const targetTime = moment(props?.data?.departureDate).tz(props?.data?.origin?.originDetails.airports? props?.data?.origin?.originDetails.airports[0]?.time_zone:props?.data?.origin?.originDetails?.time_zone)?.format("YYYY-MM-DD HH:mm:ss");
  const currentStartTime = moment(start, "HH:mm:ss");
  const currentEndTime = moment(end, "HH:mm:ss");
  console.log("findDisabled",targetTime,currentStartTime,currentEndTime)
  
  return !moment(targetTime).isBefore(currentStartTime) || moment(targetTime).isAfter(currentEndTime);
};
 const [progress, setProgress] = useState(0);
 useEffect(() => {
  console.log("isLoading",isLoading);
    let intervalId;
    if (isLoading) {
      intervalId = setInterval(() => {
        setProgress((prevProgress) => prevProgress + 10);
      }, 1000);
    } else {
      //setProgress(100);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isLoading]);


  const conFirmFlight = (e, id) => {
    const { cabin_class, passengers, slices } = location.state.message;
    let data = {
      offer_id: id,
      passangers: passengers,
      cabin_class: cabin_class,
      slices: slices,
    };
    mutate(data, {
      onSuccess: (res) => {
        if (res.data.status) {
          localStorage.setItem("client_key", props?.data?.client_key);
          navigate("/confirm-booking", {
            state: { message: res.data.data.id },
          });
        }
      },
      onError: (error) => {
        setalert({
          status: true,
          type: "error",
          message: error.response.data.error.description,
        });
        // toast.error(error.response.data.error.description);
      },
    });
  };
  useEffect(() => {
    setState(props);
  }, [props, props.mobFilter]);

  const handleSelectedTab = (tab) => {
    props.timeTabFilter(tabFilters[tab], "sort");
    setActiveTab(tab)

  };

  const Title = (key) => {
    if (key == "Recommended") {
      return (
        <div>
          <span>Recommended</span>
          <br></br>
        </div>
      );
    } else if (key == "Cheapest") {
      return (
        <div>
          <span>Cheapest</span>
          <br></br>
        </div>
      );
    } else if (key == "Quickest") {
      return (
        <div>
          <span>Quickest</span>
          <br></br>
        </div>
      );
    }
  };

  const getTabContent = (key) => {
    if (sort.includes("cheapest") ) {
      return (
        <Cheapest
          flightDetails={state?.data?.flightDetails}
          passengerDetails={state?.data?.passengerDetails}
          ViewDeatils={ViewDeatils}
          isLoading={props.isLoading}
          conFirmFlight={conFirmFlight}
          error={props.error}
          setMobFilter={props.setMobFilter}
        />
      );
    }
    if (key == "Recommended") {
      return (
        <Quickest
          flightDetails={state?.data?.flightDetails}
          passengerDetails={state?.data?.passengerDetails}
          ViewDeatils={ViewDeatils}
          isLoading={props.isLoading}
          conFirmFlight={conFirmFlight}
          error={props.error}
          setMobFilter={props.setMobFilter}
        />
      );
    }
    if (key == "Quickest") {
      return (
        <Quickest
          flightDetails={state?.data?.flightDetails}
          passengerDetails={state?.data?.passengerDetails}
          ViewDeatils={ViewDeatils}
          isLoading={props.isLoading}
          conFirmFlight={conFirmFlight}
          error={props.error}
        />
      );
    }
  };
  const { sort, stops, airlines, dep_time, arr_time } = props.filter;
  const[activetab,setActiveTab]=useState()

  return (
    <section className="searchViewCntSection">

      <div className="container">
        <Toaster
          show={alert.status}
          variant={alert.type}
          message={alert.message}
          position="top-right"
          theme="dark"
          dismiss={Dismisstoast}
        />
        {isLoading && (
  <FlightLoader progress={progress} setProgress={setProgress} />
)}
        <div className="row">
          <div className="col-xl-3 col-lg-4">
            <div className="SearchPageLeftFilterSection">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne1"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Sort By
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse  show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="filterSelctBoxRow">
                        <label>
                          <input
                            type="checkbox"
                            name="1"
                            id="1"
                            value="recommended"
                            checked={activetab=="Recommended" ? true : false}
                            onChange={(e) => {
                              console.log("eee",e.target.checked);
                              if(e.target.checked==true){
                                props.addToFilter(e, "sort");
                                setActiveTab('Recommended');
                              }
                           
                              
                            }
                          }
                          />
                          <span className="checkmark"></span>
                          Recommended
                        </label>
                      </div>
                      <div className="filterSelctBoxRow">
                        <label>
                          <input
                            type="radio"
                            name="2"
                            id="2"
                            value="cheapest"
                            checked={activetab=="Cheapest" ? true : false}
                            onChange={(e) => {
                              if(e.target.checked==true){
                                props.addToFilter(e, "sort");
                                setActiveTab('Cheapest');
                              }
                            
                            
                            }}
                          />
                          <span className="checkmark"></span>
                          Cheapest
                        </label>
                      </div>
                      <div className="filterSelctBoxRow">
                        <label>
                          <input
                            type="checkbox"
                            name="3"
                            id="3"
                            value="quickest"
                            checked={activetab=="Quickest" ? true : false}
                            onChange={(e) => {
                              if(e.target.checked==true){
                              props.addToFilter(e, "sort");
                              setActiveTab('Quickest');
                              }
                            }}
                          />
                          <span className="checkmark"></span>
                          Quickest
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item"
                  id="stopsShow"
                  style={{ display: props.mobFilter == "stopsShow" && "block" }}
                >
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree1"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                      onClick={() => props.setMobFilter("")}
                    >
                      Stops
                      <div className="filterCloseBtn">
                        <i className="fa fa-close"></i>
                      </div>
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse  show"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="filterSelctBoxRow">
                        <label>
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            value="0"
                            checked={stops?.includes("0") ? true : false}
                            onChange={(e) => props.addToFilter(e, "stops")}
                          />
                          <span className="checkmark"></span>
                          {console.log(props.stops)}
                          Nonstop
                          {props?.stops &&
                            props?.stops[0][0] > 0 &&
                            "( " + props.stops[0][0] + " )"}
                        </label>{" "}
                        <span>
                          {" "}
                          ${" "}
                          {props?.stops &&
                            props?.stops[0][0] > 0 &&
                            props.stops[0]?.amount}
                        </span>
                      </div>
                      <div className="filterSelctBoxRow">
                        <label>
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            value="2"
                            checked={stops?.includes("2") ? true : false}
                            onChange={(e) => props.addToFilter(e, "stops")}
                          />
                          <span className="checkmark"></span>1+ Stops
                          {props?.stops &&
                            props.stops[1][1] > 0 &&
                            "(" + props.stops[1][1] + ")"}
                        </label>{" "}
                        <span>
                          {" "}
                          ${" "}
                          {props?.stops &&
                            props?.stops[0][0] > 0 &&
                            props.stops[1]?.amount}
                        </span>
                      </div>
                      {/* <div className="filterSelctBoxRow">
                        <label>
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            value="2"
                            checked={stops?.includes("2") ? true : false}
                            onChange={(e) => props.addToFilter(e, "stops")}
                          />
                          <span className="checkmark"></span>
                          2+ Stops
                          {props?.stops &&
                            props.stops[2][2] > 0 &&
                            "(" + props.stops[2][2] + ")"}
                        </label>{" "}
                        <span>
                          {" "}
                          ${" "}
                          {props?.stops &&
                            props?.stops[0][0] > 0 &&
                            props.stops[2]?.amount}
                        </span>
                      </div> */}

                      <div className="FilterMobApplyBtns">
                        <a
                          className="FilterMobApplyBtn1"
                          onClick={() => props.clearFilter()}
                        >
                          Reset
                        </a>
                        <a
                          className="FilterMobApplyBtn2"
                          onClick={() => props.setMobFilter("")}
                        >
                          Submit
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item"
                  id="dprtlTimeShow"
                  style={{
                    display: props.mobFilter == "dprtlTimeShow" && "block",
                  }}
                >
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour1"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                      onClick={() => props.setMobFilter("")}>
                      Departure Time 
                      {/* ({props?.data?.origin?.originDetails?.iata_city_code}) */}
                     <div className="filterCloseBtn">
                        <i className="fa fa-close"></i>
                      </div>
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse  show"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="dprtArvltimeSelectSec">
                        <div
                          className={`dprtArvltimeSelectbox ${
                            dep_time?.includes("12:00am - 4:59am") && "active"
                          } ${findDisabled("00:00:00", "04:00:00")&& 'activeDsiabled'}`}
                        >
                          <div className="dprtArvltimeSelectboxIco">
                            <img src={earlymorning} alt="" />
                          </div>
                          <input
                            type="checkbox"
                            value="12:00am - 4:59am"
                            disabled={findDisabled("00:00:00", "04:00:00")} //setting 12 am and 4 am timings
                            checked={
                              dep_time?.includes("12:00am - 4:59am")
                                ? true
                                : false
                            }
                            onChange={(e) => props.addToFilter(e, "dep_time")}
                          />
                          <div className="dprtArvltimeSelectboxCnt">
                            <strong>Early Morning</strong>
                            <span>(12:00am - 4:59am)</span>
                          </div>
                        </div>
                        <div
                          className={`dprtArvltimeSelectbox ${
                            dep_time?.includes("05:00am - 11:59am") && "active"
                          }${findDisabled("05:00:00", "11:59:00")&& 'activeDsiabled'}`}
                        >
                          <div className="dprtArvltimeSelectboxIco">
                            <img src={morning} alt="" />
                          </div>
                          <input
                            type="checkbox"
                            value="05:00am - 11:59am"
                            disabled={findDisabled("05:00:00", "11:59:00")}
                            checked={
                              dep_time?.includes("05:00am - 11:59am")
                                ? true
                                : false
                            }
                            onChange={(e) => props.addToFilter(e, "dep_time")}
                          />
                          <div className="dprtArvltimeSelectboxCnt">
                            <strong>Morning</strong>
                            <span>(05:00am - 11:59am)</span>
                          </div>
                        </div>
                        <div
                          className={`dprtArvltimeSelectbox ${
                            dep_time?.includes("12:00pm - 05:59pm") && "active"
                          }${findDisabled("12:00pm - 05:59pm")&& 'activeDsiabled'}`}
                        >
                          <div className="dprtArvltimeSelectboxIco">
                            <img src={afternoon} alt="" />
                          </div>
                          <input
                            type="checkbox"
                            value="12:00pm - 05:59pm"
                            disabled={findDisabled("12:00:00", "17:59:00")}
                            checked={
                              dep_time?.includes("12:00pm - 05:59pm")
                                ? true
                                : false
                            }
                            onChange={(e) => props.addToFilter(e, "dep_time")}
                          />
                          <div className="dprtArvltimeSelectboxCnt">
                            <strong>Afternoon</strong>
                            <span>(12:00pm - 05:59pm)</span>
                          </div>
                        </div>
                        <div
                          className={`dprtArvltimeSelectbox ${
                            dep_time?.includes("06:00pm - 11:59pm") && "active"
                          }${findDisabled("6:00:00", "23:59:00")&& 'activeDsiabled'}`}
                        >
                          <div className="dprtArvltimeSelectboxIco">
                            <img src={evening} alt="" />
                          </div>
                          <input
                            type="checkbox"
                            value="06:00pm - 11:59pm"
                            disabled={findDisabled("6:00:00", "23:59:00")}
                            checked={
                              dep_time?.includes("06:00pm - 11:59pm")
                                ? true
                                : false
                            }
                            onChange={(e) => props.addToFilter(e, "dep_time")}
                          />
                          <div className="dprtArvltimeSelectboxCnt">
                            <strong>Evening</strong>
                            <span>(06:00pm - 11:59pm)</span>
                          </div>
                        </div>
                      </div>
                      <div className="FilterMobApplyBtns">
                        <a
                          className="FilterMobApplyBtn1"
                          onClick={() => props.clearFilter()}
                        >
                          Reset
                        </a>
                        <a
                          className="FilterMobApplyBtn2"
                          onClick={() => props.setMobFilter("")}
                        >
                          Submit
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                 <div
                  className="accordion-item"
                  id="ArrvlTimeShow"
                  style={{
                    display: props.mobFilter == "arrvlTimeBtn" && "block",
                  }}
                >
                  <h2 className="accordion-header" id="headingfive">
                    <button
                      className="accordion-button collapsed show"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsefive1"
                      aria-expanded="false"
                      aria-controls="collapsefive"
                      onClick={() => props.setMobFilter("")}
                    >
                      Arrival Time
                      <div className="filterCloseBtn">
                        <i className="fa fa-close"></i>
                      </div>
                    </button>
                  </h2>
                  <div
                    id="collapsefive"
                    className="accordion-collapse "
                    aria-labelledby="headingfive"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className="dprtArvltimeSelectSec">
                        <div
                          className={`dprtArvltimeSelectbox ${
                            arr_time?.includes("12:00am - 4:59am") && "active"
                          }`}
                        >
                          <div className="dprtArvltimeSelectboxIco">
                            <img src={earlymorning} alt="" />
                          </div>
                          <input
                            type="checkbox"
                            value="12:00am - 4:59am"
                            checked={
                              arr_time?.includes("12:00am - 4:59am")
                                ? true
                                : false
                            }
                            onChange={(e) => props.addToFilter(e, "arr_time")}
                          />
                          <div className="dprtArvltimeSelectboxCnt">
                            <strong>Early Morning</strong>
                            <span>(12:00am - 4:59am)</span>
                          </div>
                        </div>
                        <div
                          className={`dprtArvltimeSelectbox ${
                            arr_time?.includes("05:00am - 11:59am") && "active"
                          }`}
                        >
                          <div className="dprtArvltimeSelectboxIco">
                            <img src={morning} alt="" />
                          </div>
                          <input
                            type="checkbox"
                            value="05:00am - 11:59am"
                            checked={
                              arr_time?.includes("05:00am - 11:59am")
                                ? true
                                : false
                            }
                            onChange={(e) => props.addToFilter(e, "arr_time")}
                          />
                          <div className="dprtArvltimeSelectboxCnt">
                            <strong>Morning</strong>
                            <span>(05:00am - 11:59am)</span>
                          </div>
                        </div>
                        <div
                          className={`dprtArvltimeSelectbox ${
                            arr_time?.includes("12:00pm - 05:59pm") && "active"
                          }`}
                        >
                          <div className="dprtArvltimeSelectboxIco">
                            <img src={afternoon} alt="" />
                          </div>
                          <input
                            type="checkbox"
                            value="12:00pm - 05:59pm"
                            checked={
                              arr_time?.includes("12:00pm - 05:59pm")
                                ? true
                                : false
                            }
                            onChange={(e) => props.addToFilter(e, "arr_time")}
                          />
                          <div className="dprtArvltimeSelectboxCnt">
                            <strong>Afternoon</strong>
                            <span>(12:00pm - 05:59pm)</span>
                          </div>
                        </div>
                        <div
                          className={`dprtArvltimeSelectbox ${
                            arr_time?.includes("06:00pm - 11:59pm") && "active"
                          }`}
                        >
                          <div className="dprtArvltimeSelectboxIco">
                            <img src={evening} alt="" />
                          </div>
                          <input
                            type="checkbox"
                            value="06:00pm - 11:59pm"
                            checked={
                              arr_time?.includes("06:00pm - 11:59pm")
                                ? true
                                : false
                            }
                            onChange={(e) => props.addToFilter(e, "arr_time")}
                          />
                          <div className="dprtArvltimeSelectboxCnt">
                            <strong>Evening</strong>
                            <span>(06:00pm - 11:59pm)</span>
                          </div>
                        </div>
                      </div>
                      <div className="FilterMobApplyBtns">
                        <a
                          className="FilterMobApplyBtn1"
                          onClick={() => props.clearFilter()}
                        >
                          Reset
                        </a>
                        <a
                          className="FilterMobApplyBtn2"
                          onClick={() => props.setMobFilter("")}
                        >
                          Submit
                        </a>
                      </div>
                    </div>
                  </div>
                </div> 

                {/* <div className="accordion-item" id="airlinesShow">
                  <h2 className="accordion-header" id="headingeight">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseeight1"
                      aria-expanded="false"
                      aria-controls="collapseeight"
                    >
                      Airlines
                      <div className="filterCloseBtn">
                        <i className="fa fa-close"></i>
                      </div>
                    </button>
                  </h2>
                  <div
                    id="collapseeight1"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingeight"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      {props?.airLinesData?.map((obj, index) => {
                        return (
                          <div className="filterSelctBoxRow" key={index}>
                            <label>
                              <input
                                type="checkbox"
                                name={obj.name}
                                id=""
                                value={obj.name}
                                checked={
                                  airlines?.includes(obj.name) ? true : false
                                }
                                onChange={(e) =>
                                  props.addToFilter(e, "ailrlines")
                                }
                              />
                              <span className="checkmark"></span>
                              {obj.name}
                            </label>
                          </div>
                        );
                      })}
                      <div className="FilterMobApplyBtns">
                        <a className="FilterMobApplyBtn1">Reset</a>
                        <a className="FilterMobApplyBtn2">Submit</a>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-xl-9 col-lg-8">
            <div className="FlightSearchpageRightSec">
              <Tabs
                defaultActiveKey="Recommended"
                activeKey={activetab?activetab:"Recommended"}
                id="uncontrolled-tab-example"
                className="FlightSearchpageRighttopSort"
                transform={true}
                transformwidth={734}
                onSelect={handleSelectedTab}
              >
                {tabs.map((tab, index) => {
                  console.log("tab.key",tab.key);
                  return (
                    <Tab
                      eventKey={tab.key}
                      // title={sort}
                      title={Title(tab.key)}
                      id={tab.id}
                      className="FlightSearchpageRighttopSortBox"
                      key={index}
                      // onChange={getTabContent(tab.key)}
                    >
                      {getTabContent(tab.key)}
                    </Tab>
                  );
                })}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SearchView;
