import API from "../../api/interceptor";

const loginAccount = async (payload) => {
  return await API.post(`/user/login`,payload);
};
const checkAccount = async (payload) => {
  return await API.post(`/common/check-token`,payload);
};


const loginService = {
    loginAccount,
    checkAccount
};

export default loginService;