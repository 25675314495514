import React, { useEffect, useLayoutEffect, useState } from "react";
import { SideBar, NavBar, Footer } from "../shared";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../store/authSlice";
import { useCheckLogin } from "../hooks/login/useLogin";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Spinner from "../components/common/Spinner";
import { FlightLoader } from "../feature/searchList";
import { useDashboardcontent } from "../hooks/dashboard/useDashboard";
const PrivateLayout = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [isLoadings, setIsLoading] = useState(true);
  const Auth = useSelector((state) => state.auth.isLoggedIn);
  const { mutate, isError, isSuccess, isLoading, error, data, reset } =
    useCheckLogin();

  useEffect(() => {
    let data = {
      token: localStorage.getItem("token"),
    };
    mutate(data, {
      onSuccess: (res) => {
        dispatch(login(res.data.data.email));
        localStorage.setItem("token", res.data.data.token);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  }, [location, Auth]);

  // useLayoutEffect(() => {
  //   const delay = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 2000);

  //   return () => clearTimeout(delay);
  // }, []);
  const [progress, setProgress] = useState(0);
  useEffect(() => {
     let intervalId;
     if (isLoading) {
       intervalId = setInterval(() => {
         setProgress((prevProgress) => prevProgress + 10);
       }, 1000);
     } else {
     }
 
     return () => {
       clearInterval(intervalId);
     };
   }, [isLoading]);
     const {
    data: dashboardData,
    isLoading: helperLoading,
    isError: helperError,
  } = useDashboardcontent();
  if (isLoading) {
    return (
      <div className="centered">
        {" "}
        <Spinner/>

  {/* <FlightLoader progress={progress} setProgress={setProgress} /> */}

      
      </div>
    );
  }

  return (
    <div className="main_layout">
      <NavBar />
      <div className="main_wrapper_content">
        {isLoading && 
  <FlightLoader progress={progress} setProgress={setProgress} />
        }

      
    {Auth ? <Outlet /> : Navigate("/login")}
      </div>
      <Footer connectus={dashboardData?.data?.data?.follow}/>
    </div>
  );
};
export default PrivateLayout;
