export const getAminitiesBasedOnType = (cabin) => {
  console.log("cabin", cabin);
  if (cabin) {
    let newData = cabin.reduce((mergedAmenities, cabObj, index) => {
      if (cabObj.cabin.amenities) {
        Object.entries(cabObj.cabin.amenities).forEach(([key, value]) => {
          if (value["available"] || value) {
            mergedAmenities[key] = {
              color: "black",
              icon: getIconbyKeys(key),
            };
          } else {
            mergedAmenities[key] = {
              color: "grey",
              icon: getIconbyKeys(key),
            };
          }
        });
      }

      return mergedAmenities;
    }, {});
    return newData;
  }
};
export const getIconbyKeys = (key) => {
  if (key == "wifi") {
    return "fa-solid fa-wifi";
  }
  if (key == "seat") {
    return "fa-solid fa-chair";
  }
  if (key == "power") {
    return "fa-solid fa-plug";
  }
};
