import {  useQuery ,useMutation} from "@tanstack/react-query";
import bookingService from "../../service/booking";
import { useState } from "react";

export const useFlightById = () =>{
    return useMutation(bookingService.getFlightbyId);
}
export const useSlectedFlight = () =>{
    return useMutation(bookingService.selelctFlight)
}
export const usePassengers = () =>{
    return useMutation(bookingService.postPassengers)
}
export const usePassengersNames= () => {
  return useQuery(["Names"], () => bookingService.getPassengers(), {
    throwOnError: true,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
  });
};
export const usePassengersById = (value) =>{
  console.log("usePassengersById",!!value)
    return useQuery(["namesID",value], () =>bookingService.getPassengersById(value), {
    throwOnError: true,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    enabled:!!value
  });

}
export const useFlightDeatils = (value) =>{
    return useQuery(["Details",value], () =>bookingService.getFlightDeatils(value), {
    throwOnError: true,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    enabled:!!value
  });

}



export const useDiscounts = () =>{
    return useMutation(bookingService.applyOnDiscounts)
}




