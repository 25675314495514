import { useMutation, useQuery } from "@tanstack/react-query";
import dashbaordService from "../../service/dashboard";
import { useState } from "react";

// export const useSearchget = (searchParam) => {
//   const [fetch, setFetch] = useState(false);
//   const queryResult = useQuery(
//     ["search", searchParam],
//     () => dashbaordService.SearchPlace(searchParam),
//     { enabled: fetch ,refetchOnWindowFocus:false,throwOnError: true,}
//   );
//   return [() => setFetch(true), queryResult];
// };

export const useListget = (enable,payload) =>{
  return  useQuery(
    ["searchList", payload],
    () => dashbaordService.getTicketsonSerach(payload),
    {refetchOnWindowFocus:false,enabled:enable,throwOnError: true,keepPreviousData : true,
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
    }
  );
}
export const useDashboardcontent = (searchParam) => {
  return useQuery(["dashboard"], () => dashbaordService.dashboardContent(), {
    throwOnError: true,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
  });
};

export const useAddContactForm = () => {
  return useMutation(dashbaordService.addContactForm);
}
