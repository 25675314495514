import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    cabin_class:"economy",
    max_connections:1,
    passengers:[
        {
            type:"adult",
            title:"Adults",
            age:"12-0",
            count:0
        },
        {
            type:"youth",
            title:"Youth (Age 12-17)",
            age:"12-17",
            count:0
        },
        {
            type:"children",
            title:"Children (Age 2-14)",
            age:"12-17",
            count:0
        },
        {
            type:"infinats",
            title:"Infants (Under -2)",
            age:"1",
            count:0
        },      
    ],
    slices:[{
      leaving_from:"",
      departure_from:"",
      departure_date:"",
      return_date:"",
   },
   ]
 
}

export const searchSlice = createSlice({
  name:'search',
  initialState,
  reducers:{
    cabin_class_change:(state,action)=>{
      state.cabin_class = action.cabin_class;
    },
    update_passenger:(state,action)=>{
      state.passengers = action.passengers;
    },
    slices_update:(state,action)=>{
  
        state.slices = action.payload   ;
      }
  }
})

export const {cabin_class_change,update_passenger,slices_update} = searchSlice.actions;
export default searchSlice.reducer;